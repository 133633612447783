import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 30,
    backgroundColor: "white",
  },
  section: {
    marginBottom: 20,
  },
  header: {
    marginBottom: 15,
    paddingBottom: 10,
    borderBottom: "1 solid #E5E7EB",
  },
  headerTitle: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#111827",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2563EB",
    marginBottom: 10,
  },
  contentBox: {
    backgroundColor: "#F9FAFB",
    padding: 15,
    borderRadius: 4,
    marginBottom: 10,
  },
  label: {
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 5,
  },
  text: {
    fontSize: 11,
    color: "#374151",
    marginBottom: 3,
  },
  valuesList: {
    marginTop: 5,
  },
  value: {
    fontSize: 11,
    color: "#374151",
    marginBottom: 3,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  bullet: {
    marginRight: 5,
  },
  grid: {
    display: "flex",
    flexDirection: "row",
    gap: 20,
  },
  column: {
    flex: 1,
  },
  milestone: {
    fontSize: 11,
    color: "#374151",
    marginBottom: 5,
  },
  serviceArea: {
    fontSize: 11,
    color: "#374151",
    marginBottom: 5,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  dot: {
    width: 3,
    height: 3,
    backgroundColor: "#374151",
    borderRadius: "50%",
    marginRight: 5,
  },
});

const CompanyOverviewPDF = ({
  foundedYear,
  foundingStory,
  missionStatement,
  coreValues,
  milestones,
  serviceAreas,
}) => {
  console.log(foundedYear);
  return (
    <Document>
      {/* Header */}
      <View style={styles.header}>
        <Text style={styles.headerTitle}>Company Overview & History</Text>
      </View>

      {/* Company Story Section */}
      <View style={styles.section}>
        <Text style={styles.title}>Company Story</Text>
        <View style={styles.contentBox}>
          <Text style={styles.label}>Founded: {foundedYear}</Text>
          <Text style={styles.text}>{foundingStory}</Text>
        </View>
      </View>

      {/* Mission & Values Section */}
      <View style={styles.section}>
        <Text style={styles.title}>Mission & Values</Text>
        <View style={styles.contentBox}>
          <Text style={styles.label}>Mission Statement:</Text>
          <Text style={styles.text}>{missionStatement}</Text>

          <Text style={[styles.label, { marginTop: 10 }]}>Core Values:</Text>
          <View style={styles.valuesList}>
            {coreValues.map((value, index) => (
              <View key={index} style={styles.value}>
                <Text style={styles.bullet}>•</Text>
                <Text>{value}</Text>
              </View>
            ))}
          </View>
        </View>
      </View>

      {/* Grid Layout for Milestones and Service Areas */}
      <View style={styles.grid}>
        {/* Growth Milestones */}
        <View style={styles.column}>
          <Text style={styles.title}>Growth Milestones</Text>
          <View style={styles.contentBox}>
            {milestones.map((milestone, index) => (
              <Text key={index} style={styles.milestone}>
                {milestone.year}: {milestone.achievement}
              </Text>
            ))}
          </View>
        </View>

        {/* Service Areas */}
        <View style={styles.column}>
          <Text style={styles.title}>Service Areas</Text>
          <View style={styles.contentBox}>
            {serviceAreas.map((area, index) => (
              <View key={index} style={styles.serviceArea}>
                <View style={styles.dot} />
                <Text>{area}</Text>
              </View>
            ))}
          </View>
        </View>
      </View>
    </Document>
  );
};

export default CompanyOverviewPDF;
