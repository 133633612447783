//costbreakdownpdf.js
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import React from "react";
import {
  normalizeTakeOffData,
  generateSettings,
  createExportData,
} from "../utils/takeOffExport";
const styles = StyleSheet.create({
  page: {
    fontSize: 10,
    fontFamily: "Helvetica",
  },
  divisionHeader: {
    backgroundColor: "#f2f2f2",
    color: "#2563EB",
    padding: 5,
    marginBottom: 5,
    fontWeight: "bold",
    fontSize: 12,
  },
  tableHeader: {
    flexDirection: "row",
    color: "#2563EB",
    borderBottom: "1 solid #000",
    marginBottom: 2,
    fontWeight: "bold",
  },
  tableRow: {
    flexDirection: "row",
    borderBottom: "1 solid #ccc",
    paddingVertical: 2,
  },
  tableCell: {
    flex: 1,
    padding: 2,
  },
  totalRow: {
    flexDirection: "row",
    borderTop: "2 solid #000",
    marginTop: 5,
    fontWeight: "bold",
  },
  grandTotal: {
    marginTop: 10,
    padding: 5,
    backgroundColor: "#e6ffe6",
    textAlign: "right",
    fontWeight: "bold",
    fontSize: 12,
  },
  header: {
    marginBottom: 15,
    paddingBottom: 5,
    borderBottom: "2 solid #E5E7EB",
  },
  headerTitle: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#111827",
  },
});

const CostBreakDownPDF = ({ data }) => {
  // console.log("data recieved", data);
  if (data.settings && Object.keys(data.settings).length > 0) {
    data = createExportData(data.takeOff, data.settings);
    // console.log("already settings", data);
  } else {
    // console.log("data into else", data);
    const normalizedData = normalizeTakeOffData(data.takeOff);
    // console.log("new settings normalized data", normalizedData);
    const settings = generateSettings(normalizedData);
    // console.log("new settings", settings);
    data = createExportData(normalizedData, settings);
    // console.log("created data", data);
  }

  return (
    <Document>
      <View style={styles.page}>
        <View style={styles.header}>
          <Text style={styles.headerTitle}>Cost Breakdown Analysis</Text>
        </View>
        {data.divisions.map((division) => {
          const { columns } = division;
          const showCols = {
            name: columns.subdivisions,
            quantity: columns.quantities,
            units: columns.units,
            unitCost: columns.unitCosts,
            totalCost: columns.totalCosts,
            wasteFactor: columns.wasteFactor,
          };

          const headerCells = [];
          if (showCols.name)
            headerCells.push(
              <Text style={styles.tableCell} key="name">
                Subdivision
              </Text>
            );
          if (showCols.quantity)
            headerCells.push(
              <Text style={styles.tableCell} key="quantity">
                Quantity
              </Text>
            );
          if (showCols.units)
            headerCells.push(
              <Text style={styles.tableCell} key="units">
                Units
              </Text>
            );
          if (showCols.unitCost)
            headerCells.push(
              <Text style={styles.tableCell} key="unitCost">
                Unit Cost
              </Text>
            );
          if (showCols.totalCost)
            headerCells.push(
              <Text style={styles.tableCell} key="totalCost">
                Total Cost
              </Text>
            );
          if (showCols.wasteFactor)
            headerCells.push(
              <Text style={styles.tableCell} key="wasteFactor">
                Waste Factor
              </Text>
            );

          return (
            <View key={division.divisionKey} wrap={false}>
              {/* Division Header */}
              <Text style={styles.divisionHeader}>
                {division.divisionKey} - {division.divisionName}
              </Text>

              {/* Table Header */}
              {columns.subdivisions && (
                <View style={styles.tableHeader}>{headerCells}</View>
              )}

              {/* Table Body */}
              {columns.subdivisions &&
                division.subdivisions.map((sub, sIndex) => {
                  const rowCells = [];
                  if (showCols.name)
                    rowCells.push(
                      <Text style={styles.tableCell} key="name">
                        {sub.name}
                      </Text>
                    );
                  if (showCols.quantity)
                    rowCells.push(
                      <Text style={styles.tableCell} key="quantity">
                        {sub.quantity}
                      </Text>
                    );
                  if (showCols.units)
                    rowCells.push(
                      <Text style={styles.tableCell} key="units">
                        {sub.units}
                      </Text>
                    );
                  if (showCols.unitCost)
                    rowCells.push(
                      <Text style={styles.tableCell} key="unitCost">
                        {sub.unitCost !== null ? `$${sub.unitCost}` : ""}
                      </Text>
                    );
                  if (showCols.totalCost)
                    rowCells.push(
                      <Text style={styles.tableCell} key="totalCost">
                        {sub.totalCost !== null
                          ? `$${sub.totalCost.toLocaleString()}`
                          : ""}
                      </Text>
                    );
                  if (showCols.wasteFactor)
                    rowCells.push(
                      <Text style={styles.tableCell} key="wasteFactor">
                        {sub.wasteFactor}
                      </Text>
                    );

                  return (
                    <View style={styles.tableRow} key={sIndex}>
                      {rowCells}
                    </View>
                  );
                })}

              {/* Division Total */}
              <View style={styles.totalRow}>
                <Text
                  style={[
                    styles.tableCell,
                    { textAlign: "right", fontWeight: "bold" },
                  ]}
                >
                  Division Total: ${division.total.toLocaleString()}
                </Text>
              </View>
            </View>
          );
        })}
        {/* Grand Total */}
        <Text style={styles.grandTotal}>
          Grand Total: ${data.grandTotal.toLocaleString()}
        </Text>
      </View>
    </Document>
  );
};

export default CostBreakDownPDF;
