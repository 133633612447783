import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Drawer, Box, Typography, IconButton, CircularProgress } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import MessageLeft from './MessageLeft';
import MessageRight from './MessageRight';
import TextInput from './TextInput';
import { getChatMessages } from '../../services/jobService';

const LIMIT = 20;

const ChatDrawer = React.memo(function ChatDrawer({ open, onClose, onChatSend, jobId }) {
  const [messages, setMessages] = useState([]);
  const [sending, setSending] = useState(false);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const scrollContainerRef = useRef(null);
  const [loadingOlder, setLoadingOlder] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  // Local state for the chat input
  const [chatPrompt, setChatPrompt] = useState('');

  // Load messages function
  const loadMessages = useCallback(async (currentOffset, initial = false) => {
    if (!jobId) return;
    try {
      if (!initial) setLoadingOlder(true);
      const data = await getChatMessages(jobId, LIMIT, currentOffset);
      const msgs = data.messages || [];
      const count = data.total_count || 0;
      if (initial) {
        setTotalCount(count);
      }

      if (msgs.length < LIMIT) {
        setHasMore(false);
      }

      if (!initial) {
        // Prepend older messages
        setMessages((prev) => [...msgs, ...prev]);
      } else {
        // Initial load
        setMessages(msgs);
      }

      if (!initial) setLoadingOlder(false);

      // After loading older messages, maintain scroll position
      if (!initial && scrollContainerRef.current) {
        const container = scrollContainerRef.current;
        const previousHeight = container.scrollHeight;
        // Wait a tick for the new messages to render
        setTimeout(() => {
          const newHeight = container.scrollHeight;
          // Adjust scroll to keep the user at the same position
          container.scrollTop = newHeight - previousHeight;
        }, 0);
      }
    } catch (error) {
      console.error('Error loading messages:', error);
    }
  }, [jobId]);

  // Fetch initial messages when chat opens
  useEffect(() => {
    if (open && jobId) {
      // Reset state
      setMessages([]);
      setOffset(0);
      setHasMore(true);
      setTotalCount(0);

      (async () => {
        // First, load messages from the "end" of conversation
        // We'll fetch the messages count and then calculate offset
        const data = await getChatMessages(jobId, LIMIT, 0);
        if (data && data.total_count !== undefined) {
          const count = data.total_count;
          setTotalCount(count);
          // Calculate starting offset so we load the newest messages at bottom
          let startOffset = count > LIMIT ? count - LIMIT : 0;
          // If we already got initial data from the first call, we need to reload properly
          // Let's just do that using the loadMessages function now that we know total_count
          setOffset(startOffset);
          loadMessages(startOffset, true);
        }
      })();
    }
  }, [open, jobId, loadMessages]);

  const handleSend = async () => {
    if (!chatPrompt.trim()) return;
    setSending(true);
    const newMsgs = await onChatSend(chatPrompt); // Pass the prompt to parent's send function
    setSending(false);
    setChatPrompt(''); // Clear input after sending

    // Append these new messages at the bottom (since they are newest)
    setMessages((prev) => [...prev, ...newMsgs]);

    // Scroll to bottom after sending
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
    }

    // Increase totalCount since we added a new pair of messages (1 user + 1 assistant)
    setTotalCount((prevCount) => prevCount + newMsgs.length);
  };

  useEffect(() => {
    // On initial render or update, if this is the first load, scroll to bottom
    if (scrollContainerRef.current && messages.length > 0) {
      scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const handleScroll = () => {
    const container = scrollContainerRef.current;
    if (!container) return;

    // If near the top, load older messages
    if (container.scrollTop < 50 && hasMore && !loadingOlder) {
      // Calculate new offset to fetch older messages
      const newOffset = Math.max(offset - LIMIT, 0);
      if (newOffset < offset) {
        setOffset(newOffset);
        loadMessages(newOffset);
      }
    }
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { width: '500px', maxWidth: '90vw' },
      }}
    >
      <Box sx={{ position: 'relative', height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'primary.main',
            color: 'white',
            p: 1,
          }}
        >
          <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
            AI Assistant
          </Typography>
          <IconButton onClick={onClose} sx={{ color: 'white' }} aria-label="Close Chat Drawer">
            <CloseIcon />
          </IconButton>
        </Box>

        <Box
          sx={{ flexGrow: 1, p: 2, overflowY: 'auto' }}
          ref={scrollContainerRef}
          onScroll={handleScroll}
        >
          {loadingOlder && (
            <Box sx={{ textAlign: 'center', mb: 1 }}>
              <CircularProgress size={24} />
            </Box>
          )}
          {messages.map((msg, index) =>
            msg.role === 'assistant' ? (
              <MessageLeft
                key={index}
                message={msg.message}
                timestamp={msg.timestamp}
                avatarDisp={msg.avatar}
              />
            ) : (
              <MessageRight
                key={index}
                message={msg.message}
                timestamp={msg.timestamp}
                avatarDisp={msg.avatar}
              />
            )
          )}
          {sending && (
            <Box sx={{ mt: 2, textAlign: 'center' }}>
              <CircularProgress size={24} />
            </Box>
          )}
        </Box>

        <TextInput
          value={chatPrompt}
          onChange={setChatPrompt}
          onSend={handleSend}
          disabled={sending}
        />
      </Box>
    </Drawer>
  );
});

export default ChatDrawer;
