// src/services/annotationService.js
import apiAxios from './axiosConfig';

/**
 * GET annotations for a given job & imageUrl
 */
export async function fetchAnnotations(jobId, imageUrl) {
  try {
    const url = `/jobs/${jobId}/annotations?image_url=${encodeURIComponent(imageUrl)}`;
    const res = await apiAxios.get(url);
    return res.data; 
  } catch (error) {
    console.error('Error fetching annotations:', error.response?.data || error);
    return [];
  }
}


export async function createAnnotation(jobId, annotationData) {
    // annotationData can have { image_url, x, y, width, height, type, label, tag, color }
    try {
      const url = `/jobs/${jobId}/annotations`;
      const res = await apiAxios.post(url, annotationData);
      return res.data;
    } catch (error) {
      console.error('Error creating annotation:', error.response?.data || error);
      return null;
    }
  }
  
  export async function updateAnnotation(jobId, annotationId, updateData) {
    // updateData can have { x, y, width, height, type, label, tag, color }
    try {
      const url = `/jobs/${jobId}/annotations/${annotationId}`;
      const res = await apiAxios.put(url, updateData);
      return res.data;
    } catch (error) {
      console.error('Error updating annotation:', error.response?.data || error);
      return null;
    }
  }

/**
 * Delete annotation
 */
export async function deleteAnnotation(jobId, annotationId) {
  try {
    console.log('jobId:', jobId);
    const url = `/jobs/${jobId}/annotations/${annotationId}`;
    const res = await apiAxios.delete(url);
    console.log('res:', res);
    return res.data;
  } catch (error) {
    console.error('Error deleting annotation:', error.response?.data || error);
    return null;
  }
}


export async function clearAnnotations(jobId, imageUrl) {
    try {
      const url = `/jobs/${jobId}/annotations/clear?image_url=${encodeURIComponent(imageUrl)}`;
      const res = await apiAxios.delete(url);
      return res.data;
    } catch (error) {
      console.error('Error clearing annotations:', error.response?.data || error);
      return null;
    }
}

/**
 * Create comment for an annotation
 */
export async function createComment(jobId, annotationId, text) {
  try {
    const url = `/jobs/${jobId}/annotations/${annotationId}/comments`;
    const res = await apiAxios.post(url, { text });
    return res.data;
  } catch (error) {
    console.error('Error creating comment:', error.response?.data || error);
    return null;
  }
}

/**
 * Delete comment from an annotation
 */
export async function deleteComment(jobId, annotationId, commentId) {
  try {
    const url = `/jobs/${jobId}/annotations/${annotationId}/comments/${commentId}`;
    const res = await apiAxios.delete(url);
    return res.data;
  } catch (error) {
    console.error('Error deleting annotation comment:', error.response?.data || error);
    return null;
  }
}
