// src/components/Authentication/ResetPassword.js

import React, { useState } from 'react';
import { TextField, Button, Typography, Card, CardContent } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { resetPassword } from '../../services/userService';
import Navbar from '../Navbar/Navbar';

function ResetPassword() {
  const { token } = useParams();
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    try {
      const response = await resetPassword(token, password);
      setMessage(response.message || 'Password reset successfully!');
      setTimeout(() => navigate('/login'), 2000);
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to reset password.');
    }
  };

  return (
    <div>
      <Navbar />
      <Card sx={{ maxWidth: 400, margin: 'auto', mt: 10 }}>
        <CardContent>
          <Typography variant="h5" gutterBottom>Reset Password</Typography>
          <form onSubmit={handleResetPassword}>
            <TextField
              label="New Password"
              type="password"
              fullWidth
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <TextField
              label="Confirm Password"
              type="password"
              fullWidth
              margin="normal"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />

            <div className="mt-2 text-sm text-gray-600">
                <Typography variant="body2" color="text.secondary">
                Password must contain:
                <ul className="list-disc pl-6 mt-1 space-y-1">
                    <li>At least 12 characters</li>
                    <li>At least one uppercase letter</li>
                    <li>At least one lowercase letter</li>
                    <li>At least one number</li>
                    <li>At least one special character</li>
                </ul>
                </Typography>
            </div>


            <Button variant="contained" type="submit" fullWidth sx={{ mt: 2 }}>
              Reset Password
            </Button>
          </form>
          {message && <Typography sx={{ mt: 2, color: 'green' }}>{message}</Typography>}
          {error && <Typography sx={{ mt: 2, color: 'red' }}>{error}</Typography>}
        </CardContent>
      </Card>
    </div>
  );
}

export default ResetPassword;
