import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Button,
  Alert, 
  Snackbar,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import LineItemsTable from "./LineItemsTable";
import { fetchUserPricingData, updateFileStatus } from "../../services/pricingService";
import Navbar from "../Navbar/Navbar";

const CustomPricing = () => {
  const [data, setData] = useState([]); // State to hold fetched data
  const [selectedFileData, setSelectedFileData] = useState(null); // Selected file's extracted text
  const [selectedFile, setSelectedFile] = useState(null); // Selected file name
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("description");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [snackbarOpen, setSnackbarOpen] = useState(false); // Snackbar state
  const [error, setError] = useState("");

  // Fetch data from backend
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchUserPricingData(); // Replace with your backend API endpoint
        console.log(response);
        if (response.error) {
          setError(response.error);
          setSnackbarOpen(true);
          console.error("Error fetching custom pricing data:", error);
        } else {
          setData(response.map((file) => ({ ...file })));
        }
      } catch (error) {
        setError(error);
        setSnackbarOpen(true)
        console.error("Error fetching custom pricing data:", error);
      }
    };
    fetchData();
  }, []);

  // Handle file click
  const handleFileClick = (fileData) => {
    setSelectedFileData(fileData.extracted_text);
    setSelectedFile(fileData);
    setPage(0); // Reset pagination to the first page
  };

  // Handle sorting
  const handleSort = (column) => {
    const isAsc = orderBy === column && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(column);
  };

  // Define columns dynamically based on selected data
  const columns = selectedFileData
    ? Object.keys(selectedFileData[0] || {}).map((key) => ({
      id: key,
      label: key.replace(/_/g, " ").toUpperCase(),
    }))
    : [];

  // Update status of the file when approved/rejected
  const updateStatus = async (file, status) => {
    try {
      const response = await updateFileStatus(file.id, status);
  
      // Check if the response is successful
      if (response.data) {
        console.log(`Updated file with ID = ${file.id} to status = ${status}`);
  
        // Update the file status in the local state
        setData((prevData) =>
          prevData.map((f) =>
            f.id === file.id ? { ...f, status } : f
          )
        );
  
        setSelectedFile(null);
        setSelectedFileData(null);
      } else {
        console.error("Error updating file status:", response.error);
  
        // Show an error message in the Snackbar
        setError(response.error || "Failed to update file status.");
        // setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Unexpected error updating file status:", error);
  
      // Show an unexpected error message in the Snackbar
      setError(error.message || "An unexpected error occurred.");
      // setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };
  
  
  

  // Handle Approve
  const onApprove = () => {
    if(selectedFile){
      console.log(`User Approved the file ${selectedFile.id}`);
      updateStatus(selectedFile, "approved");
    }
  }

  // Handle Reject
  const onReject = () => {
    if(selectedFile){
      console.log(`User Rejected the file ${selectedFile.id}`);
      updateStatus(selectedFile, "rejected");
    }
  }

  const getStatusIcon = (file) => {
    // console.log(`Status of the selected file = ${file.file_name}, ${file.id}, ${file.status}`);
    let status = file.status;
    switch (status) {
      case "approved":
        return <CheckCircleIcon color="success" />;
      case "rejected":
        return <CancelIcon color="error" />;
      default:
        return <HourglassEmptyIcon color="action" />;
    }
  };


  // const handleDeleteRow = (index) => {
  //     setSelectedFileData((prev) => prev.filter((_, i) => i !== index));
  //   };

  // const handleSaveRow = (index, updatedRow) => {
  //     setSelectedFileData((prev) =>
  //       prev.map((row, i) => (i === index ? updatedRow : row))
  //     );
  //   };    

  return (
    <Box
    sx={{
      p: 3,
      marginTop: "64px",
      overflowY: "auto", // Make content scrollable
      position: "relative", // Add this
      zIndex: 0, // Add this to ensure content stays below navbar
    }}
  >
    <Navbar />
    <Box sx={{ display: "flex", height: "100vh" }}>
      {/* Left Panel: File Names */}
      <Box
        sx={{
          width: "30%",
          borderRight: "1px solid #ddd",
          overflowY: "auto",
        }}
      >
        <Typography variant="h6" sx={{ padding: "1rem", textAlign: "center" }}>
          Files
        </Typography>
        <List>
          {data.map((file, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton onClick={() => handleFileClick(file)}>
                <ListItemText primary={file.file_name} />
                {getStatusIcon(file)}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>

      {/* Right Panel: Table */}
      <Box sx={{ flexGrow: 1, padding: "1rem" }}>
        <Typography variant="h6" sx={{ paddingBottom: "1rem" }}>
          {selectedFileData
            ? `Extracted Data for ${selectedFile.file_name}`
            : "Select a file to view extracted data"}
        </Typography>
        {selectedFileData ? (
          //   <LineItemsTable
          //   lineItems={selectedFileData}
          //   order={order}
          //   orderBy={orderBy}
          //   handleSort={handleSort}
          //   rowsPerPage={rowsPerPage}
          //   page={page}
          //   handleChangePage={(event, newPage) => setPage(newPage)}
          //   handleChangeRowsPerPage={(event) => setRowsPerPage(+event.target.value)}
          //   onDeleteRow={handleDeleteRow}
          //   onSaveRow={handleSaveRow}
          // />
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            {/* Approve and Reject Buttons */}
            <Box sx={{ display: "flex", justifyContent: "flex-end", padding: "1rem" }}>
              <Button
                variant="contained"
                color="success"
                onClick={onApprove}
                sx={{ marginRight: "1rem" }}
              >
                Approve
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={onReject}
              >
                Reject
              </Button>
            </Box>

            <LineItemsTable
              lineItems={selectedFileData} // Pass extracted data
              columns={columns} // Pass dynamic columns
              order={order}
              orderBy={orderBy}
              handleSort={handleSort}
              rowsPerPage={rowsPerPage}
              page={page}
              handleChangePage={(event, newPage) => setPage(newPage)}
              handleChangeRowsPerPage={(event) => setRowsPerPage(+event.target.value)}
            />

          </Paper>

        ) : (
          <Typography>Select a file to see its data.</Typography>
        )}
      </Box>
    </Box>
    {/* Snackbar for Notifications */}
    <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CustomPricing;
