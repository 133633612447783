import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TextField,
  Stack,
  ToggleButtonGroup,
  ToggleButton,
  IconButton,
  Box,
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  InputLabel,
  FormControl
} from '@mui/material';
import {
  Undo as UndoIcon,
  Redo as RedoIcon,
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
  ClearAll as ClearAllIcon,
  PanTool as PanToolIcon,
  Straighten as StraightenIcon,
  CropSquare as CropSquareIcon,
  Circle as CircleIcon,
  Delete as DeleteIcon,
  Mouse as MouseIcon
} from '@mui/icons-material';

import { Autocomplete } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import {
  fetchAnnotations,
  createAnnotation,
  updateAnnotation,
  deleteAnnotation,
  createComment
} from '../../services/annotationService';

function MeasureAnnotateModal({
  open,
  onClose,
  jobId,
  imageUrl,
  dpi = 300
}) {
  const canvasRef = useRef(null);
  const [img, setImg] = useState(null);

  // shapes includes DB data + newly created shapes
  const [shapes, setShapes] = useState([]);
  const [redoStack, setRedoStack] = useState([]);

  // Tools
  const [currentTool, setCurrentTool] = useState('select');
  const [ghostShape, setGhostShape] = useState(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [isPanning, setIsPanning] = useState(false);
  const [panStart, setPanStart] = useState(null);

  // Zoom / panning
  const [zoom, setZoom] = useState(1.0);
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  // selected shape
  const [selectedShapeId, setSelectedShapeId] = useState(null);

  // new comment text
  const [newComment, setNewComment] = useState('');

  // We'll also manage label/tag/color in a small state for editing
  const [editLabel, setEditLabel] = useState('');
  const [editColor, setEditColor] = useState('#FF0000');

  // For the Tag field we use an Autocomplete, so we keep `editTag` as a string
  const [editTag, setEditTag] = useState('');

  // We'll keep a local array of all tags seen so far
  const [allTags, setAllTags] = useState([]);

  // ----------- UTILS: DB <-> local shape -----------
  function dbAnnotationToShape(ann) {
    const p1 = { x: ann.x, y: ann.y };
    const p2 = { x: ann.x + ann.width, y: ann.y + ann.height };
    return {
      id: ann.id,
      type: ann.shape_type,
      p1,
      p2,
      label: ann.label || '',
      tag: ann.tag || '',
      color: ann.color || '',
      comments: ann.comments.map((c) => ({
        id: c.id,
        text: c.text
      }))
    };
  }

  function shapeToDbPayload(sh) {
    const x = sh.p1.x;
    const y = sh.p1.y;
    const width = sh.p2.x - sh.p1.x;
    const height = sh.p2.y - sh.p1.y;
    return {
      image_url: imageUrl,
      x,
      y,
      width,
      height,
      type: sh.type,
      label: sh.label || null,
      tag: sh.tag || null,
      color: sh.color || null
    };
  }

  // ----------- On modal open, load image & annotations -----------
  useEffect(() => {
    if (!open) return;
    if (!imageUrl) return;

    // load image
    const background = new Image();
    background.src = imageUrl;
    background.onload = () => setImg(background);

    // fetch existing
    fetchAnnotations(jobId, imageUrl)
      .then((annots) => {
        const shapesLocal = annots.map(dbAnnotationToShape);
        setShapes(shapesLocal);
      })
      .catch((err) => console.error('Error fetching annotations:', err));
  }, [open, imageUrl, jobId]);

  // ----------- Rebuild allTags from shapes whenever shapes changes -----------
  useEffect(() => {
    // Grab all non-empty tags
    const uniqueTags = new Set(
      shapes
        .map((s) => s.tag.trim())
        .filter((tag) => tag.length > 0)
    );
    setAllTags(Array.from(uniqueTags));
  }, [shapes]);

  // ----------- Canvas draw -----------
  const drawCanvas = useCallback(() => {
    const canvas = canvasRef.current;
    if (!canvas || !img) return;

    const ctx = canvas.getContext('2d');
    ctx.save();
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    ctx.translate(offset.x, offset.y);
    ctx.scale(zoom, zoom);

    // background
    ctx.drawImage(img, 0, 0);

    const drawLine = (p1, p2, color = 'red') => {
      ctx.beginPath();
      ctx.moveTo(p1.x, p1.y);
      ctx.lineTo(p2.x, p2.y);
      ctx.strokeStyle = color;
      ctx.stroke();

      // measure
      const dx = p2.x - p1.x;
      const dy = p2.y - p1.y;
      const distPx = Math.sqrt(dx * dx + dy * dy);
      const distIn = distPx / dpi;
      const midX = (p1.x + p2.x) / 2;
      const midY = (p1.y + p2.y) / 2;
      ctx.fillStyle = color;
      ctx.font = `${14 / zoom}px Arial`;
      ctx.fillText(`${distIn.toFixed(2)} in`, midX + 5, midY - 5);
    };

    const drawRect = (p1, p2, color = 'red') => {
      const left = Math.min(p1.x, p2.x);
      const top = Math.min(p1.y, p2.y);
      const right = Math.max(p1.x, p2.x);
      const bottom = Math.max(p1.y, p2.y);
      const w = right - left;
      const h = bottom - top;
      ctx.beginPath();
      ctx.strokeStyle = color;
      ctx.rect(left, top, w, h);
      ctx.stroke();

      const wIn = w / dpi;
      const hIn = h / dpi;
      const area = wIn * hIn;
      ctx.fillStyle = color;
      ctx.font = `${14 / zoom}px Arial`;
      ctx.fillText(
        `${wIn.toFixed(2)}x${hIn.toFixed(2)} in, area=${area.toFixed(2)} in²`,
        left + 5,
        top - 5
      );
    };

    const drawCircle = (p1, p2, color = 'red') => {
      const dx = p2.x - p1.x;
      const dy = p2.y - p1.y;
      const distPx = Math.sqrt(dx * dx + dy * dy);

      ctx.beginPath();
      ctx.strokeStyle = color;
      ctx.arc(p1.x, p1.y, distPx, 0, 2 * Math.PI);
      ctx.stroke();

      const rIn = distPx / dpi;
      const area = Math.PI * (rIn * rIn);
      ctx.fillStyle = color;
      ctx.font = `${14 / zoom}px Arial`;
      ctx.fillText(
        `r=${rIn.toFixed(2)} in, area=${area.toFixed(2)} in²`,
        p1.x + distPx + 5,
        p1.y - distPx - 5
      );
    };

    // draw existing shapes
    shapes.forEach((sh) => {
      // use shape's color if available, else red
      let shapeColor = sh.color || 'red';
      if (sh.id === selectedShapeId) {
        // highlight selected shape
        shapeColor = 'green';
      }
      switch (sh.type) {
        case 'line':
          drawLine(sh.p1, sh.p2, shapeColor);
          break;
        case 'rect':
          drawRect(sh.p1, sh.p2, shapeColor);
          break;
        case 'circle':
          drawCircle(sh.p1, sh.p2, shapeColor);
          break;
        default:
          break;
      }
    });

    // ghost shape (blue)
    if (ghostShape) {
      switch (ghostShape.type) {
        case 'line':
          drawLine(ghostShape.p1, ghostShape.p2, 'blue');
          break;
        case 'rect':
          drawRect(ghostShape.p1, ghostShape.p2, 'blue');
          break;
        case 'circle':
          drawCircle(ghostShape.p1, ghostShape.p2, 'blue');
          break;
        default:
          break;
      }
    }

    ctx.restore();
  }, [img, shapes, ghostShape, zoom, offset, selectedShapeId, dpi]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas || !img) return;

    canvas.width = img.naturalWidth;
    canvas.height = img.naturalHeight;

    // auto-fit
    const maxWidth = 0.9 * window.innerWidth;
    const maxHeight = 0.8 * window.innerHeight;
    const scaleX = maxWidth / img.naturalWidth;
    const scaleY = maxHeight / img.naturalHeight;
    setZoom(Math.min(scaleX, scaleY));

    drawCanvas();
  }, [img, open, drawCanvas]);

  useEffect(() => {
    drawCanvas();
  }, [shapes, ghostShape, offset, zoom, drawCanvas]);

  // ----------- Mouse logic -----------
  const handleMouseDown = (e) => {
    if (!canvasRef.current) return;

    if (e.altKey) {
      // panning
      setIsPanning(true);
      setPanStart({ x: e.clientX, y: e.clientY });
      return;
    }

    if (currentTool === 'select') {
      return;
    }

    // start drawing shape
    setIsDrawing(true);
    const rect = canvasRef.current.getBoundingClientRect();
    const x = (e.clientX - rect.left - offset.x) / zoom;
    const y = (e.clientY - rect.top - offset.y) / zoom;

    setGhostShape({
      id: uuidv4(),
      type: currentTool,
      p1: { x, y },
      p2: { x, y },
      label: '',
      tag: '',
      color: '',
      comments: []
    });
  };

  const handleMouseMove = (e) => {
    if (isPanning && panStart) {
      const dx = e.clientX - panStart.x;
      const dy = e.clientY - panStart.y;
      setOffset((prev) => ({ x: prev.x + dx, y: prev.y + dy }));
      setPanStart({ x: e.clientX, y: e.clientY });
      return;
    }
    if (isDrawing && ghostShape) {
      const rect = canvasRef.current.getBoundingClientRect();
      const x = (e.clientX - rect.left - offset.x) / zoom;
      const y = (e.clientY - rect.top - offset.y) / zoom;
      setGhostShape((prev) => ({
        ...prev,
        p2: { x, y }
      }));
    }
  };

  const handleMouseUp = async () => {
    if (isPanning) {
      setIsPanning(false);
      setPanStart(null);
      return;
    }
    if (currentTool === 'select') {
      return;
    }
    if (isDrawing && ghostShape) {
      setIsDrawing(false);
      setRedoStack([]);
      const newShape = { ...ghostShape };
      setGhostShape(null);

      // persist
      try {
        const payload = shapeToDbPayload(newShape);
        const ann = await createAnnotation(jobId, payload);
        if (ann && ann.id) {
          const realShape = dbAnnotationToShape(ann);
          setShapes((prev) => [...prev, realShape]);
        } else {
          console.error('Failed to create annotation in DB.');
        }
      } catch (err) {
        console.error('Error creating annotation:', err);
      }
    }
  };

  const handleMouseLeave = () => {
    if (isPanning) {
      setIsPanning(false);
      setPanStart(null);
    }
    if (isDrawing && ghostShape) {
      setIsDrawing(false);
      setGhostShape(null);
      setRedoStack([]);
    }
  };

  // ----------- Canvas click selection -----------
  const handleCanvasClick = (e) => {
    if (!canvasRef.current) return;
    if (currentTool !== 'select') return;
    const rect = canvasRef.current.getBoundingClientRect();
    const clickX = (e.clientX - rect.left - offset.x) / zoom;
    const clickY = (e.clientY - rect.top - offset.y) / zoom;

    for (let i = shapes.length - 1; i >= 0; i--) {
      const s = shapes[i];
      const minX = Math.min(s.p1.x, s.p2.x);
      const maxX = Math.max(s.p1.x, s.p2.x);
      const minY = Math.min(s.p1.y, s.p2.y);
      const maxY = Math.max(s.p1.y, s.p2.y);
      if (clickX >= minX && clickX <= maxX && clickY >= minY && clickY <= maxY) {
        setSelectedShapeId(s.id);
        setNewComment('');
        setEditLabel(s.label || '');
        setEditTag(s.tag || '');
        setEditColor(s.color || '#FF0000');
        return;
      }
    }

    // if none found
    setSelectedShapeId(null);
    setNewComment('');
  };

  // ----------- Undo / Redo -----------
  const handleUndo = () => {
    if (!shapes.length) return;
    const popped = shapes[shapes.length - 1];
    setShapes(shapes.slice(0, -1));
    setRedoStack([...redoStack, popped]);
    if (popped.id === selectedShapeId) {
      setSelectedShapeId(null);
    }
  };

  const handleRedo = () => {
    if (!redoStack.length) return;
    const revived = redoStack[redoStack.length - 1];
    setRedoStack(redoStack.slice(0, -1));
    setShapes([...shapes, revived]);
  };

  const handleClearAll = () => {
    setShapes([]);
    setRedoStack([]);
    setGhostShape(null);
    setIsDrawing(false);
    setSelectedShapeId(null);
    setNewComment('');
  };

  // ----------- Zoom -----------
  const handleZoomIn = () => setZoom((z) => z * 1.25);
  const handleZoomOut = () => setZoom((z) => z * 0.8);

  // ----------- Keyboard shortcuts -----------
  const handleKeyDown = useCallback(
    (e) => {
      if (
        (e.ctrlKey || e.metaKey) &&
        !e.shiftKey &&
        e.key.toLowerCase() === 'z'
      ) {
        e.preventDefault();
        handleUndo();
      } else if (
        (e.ctrlKey || e.metaKey) &&
        e.shiftKey &&
        e.key.toLowerCase() === 'z'
      ) {
        e.preventDefault();
        handleRedo();
      } else if ((e.ctrlKey || e.metaKey) && e.key.toLowerCase() === 'y') {
        e.preventDefault();
        handleRedo();
      }
    },
    [handleUndo, handleRedo]
  );

  useEffect(() => {
    if (!open) return;
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [open, handleKeyDown]);

  // ----------- Comments -----------
  const handleAddComment = async () => {
    if (!selectedShapeId || !newComment.trim()) return;
    try {
      const res = await createComment(jobId, selectedShapeId, newComment.trim());
      if (!res) {
        console.error('Failed to create comment in DB');
        return;
      }
      setShapes((prev) =>
        prev.map((sh) => {
          if (sh.id === selectedShapeId) {
            return {
              ...sh,
              comments: [...sh.comments, { id: res.id, text: res.text }]
            };
          }
          return sh;
        })
      );
      setNewComment('');
    } catch (err) {
      console.error('Error creating comment:', err);
    }
  };

  // ----------- Delete shape -----------
  const handleDeleteShape = async () => {
    if (!selectedShapeId) return;
    try {
      await deleteAnnotation(jobId, selectedShapeId);
      setShapes((prev) => prev.filter((s) => s.id !== selectedShapeId));
      setSelectedShapeId(null);
      setNewComment('');
    } catch (err) {
      console.error('Error deleting annotation:', err);
    }
  };

  // ----------- Update label/tag/color -----------
  const handleUpdateAnnotation = async () => {
    if (!selectedShapeId) return;
    const shape = shapes.find((s) => s.id === selectedShapeId);
    if (!shape) return;

    const updateData = {
      label: editLabel.trim() || null,
      tag: editTag.trim() || null,
      color: editColor.trim() || null
    };
    const res = await updateAnnotation(jobId, selectedShapeId, updateData);
    if (!res) {
      console.error('Failed to update annotation');
      return;
    }
    // update local
    setShapes((prev) =>
      prev.map((sh) => {
        if (sh.id === selectedShapeId) {
          return {
            ...sh,
            label: editLabel.trim() || '',
            tag: editTag.trim() || '',
            color: editColor.trim() || ''
          };
        }
        return sh;
      })
    );

    // If user typed a new tag that wasn't in allTags, add it
    if (editTag.trim() && !allTags.includes(editTag.trim())) {
      setAllTags((prev) => [...prev, editTag.trim()]);
    }
  };

  // ----------- UI for shape listing -----------
  const selectedShape = shapes.find((s) => s.id === selectedShapeId) || null;
  const shapeComments = selectedShape?.comments || [];

  const shapeLabel = (sh, index) => {
    if (sh.label && sh.label.trim()) {
      return `${sh.label} (${sh.type})`;
    }
    const prefix = sh.type[0].toUpperCase() + sh.type.slice(1);
    return `${prefix} #${index + 1}`;
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={false}
      fullWidth={false}
      PaperProps={{ style: { overflow: 'hidden' } }}
    >
      <DialogTitle>Measure & Annotate (with Tagging/Label/Color + Autocomplete Tags)</DialogTitle>

      <DialogContent
        dividers
        style={{
          width: '90vw',
          height: '80vh',
          position: 'relative',
          cursor: isPanning ? 'grabbing' : 'crosshair'
        }}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseLeave}
        onClick={handleCanvasClick}
      >
        <canvas ref={canvasRef} />

        <Typography
          variant="caption"
          sx={{ position: 'absolute', bottom: 4, right: 4 }}
        >
          <PanToolIcon sx={{ fontSize: 16 }} /> Hold ALT + Drag to Pan
        </Typography>

        {/* Right Panel */}
        <Box
          sx={{
            position: 'absolute',
            top: 10,
            right: 10,
            width: 300,
            bgcolor: '#f9f9f9',
            border: '1px solid #ddd',
            borderRadius: 2,
            p: 2,
            zIndex: 9999,
            maxHeight: '70vh',
            overflowY: 'auto'
          }}
          onMouseDown={(e) => e.stopPropagation()}
          onMouseUp={(e) => e.stopPropagation()}
          onClick={(e) => e.stopPropagation()}
        >
          <Typography variant="subtitle2" gutterBottom>
            Shapes
          </Typography>

          <List dense>
            {shapes.map((sh, idx) => {
              const isSelected = sh.id === selectedShapeId;
              return (
                <ListItemButton
                  key={sh.id}
                  selected={isSelected}
                  onClick={() => {
                    setSelectedShapeId(sh.id);
                    setNewComment('');
                    setEditLabel(sh.label || '');
                    setEditTag(sh.tag || '');
                    setEditColor(sh.color || '#FF0000');
                  }}
                >
                  <ListItemText
                    primary={shapeLabel(sh, idx)}
                    secondary={
                      sh.tag
                        ? `Tag: ${sh.tag} | ${sh.comments.length} comment(s)`
                        : `${sh.comments.length} comment(s)`
                    }
                  />
                </ListItemButton>
              );
            })}
          </List>

          {selectedShape && (
            <Box sx={{ mt: 2 }}>
              <Button
                variant="outlined"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={handleDeleteShape}
                sx={{ mb: 1 }}
              >
                Delete Shape
              </Button>

              {/* Label, Tag, Color Editing */}
              <Box sx={{ mb: 2 }}>
                <TextField
                  label="Label"
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={{ mb: 1 }}
                  value={editLabel}
                  onChange={(e) => setEditLabel(e.target.value)}
                />

                {/* Autocomplete for Tag */}
                <Autocomplete
                  freeSolo
                  size="small"
                  options={allTags}
                  value={editTag}
                  onChange={(event, newValue) => {
                    // user selected from dropdown
                    setEditTag(newValue || '');
                  }}
                  onInputChange={(event, newInputValue) => {
                    // user typed a new value
                    setEditTag(newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tag"
                      variant="outlined"
                      placeholder="e.g. Door, Window, etc."
                      sx={{ mb: 1 }}
                    />
                  )}
                />

                <FormControl fullWidth size="small" sx={{ mb: 1 }}>
                  <InputLabel id="color-label">Color</InputLabel>
                  <Select
                    labelId="color-label"
                    label="Color"
                    value={editColor}
                    onChange={(e) => setEditColor(e.target.value)}
                  >
                    <MenuItem value="#FF0000">Red</MenuItem>
                    <MenuItem value="#00FF00">Green</MenuItem>
                    <MenuItem value="#0000FF">Blue</MenuItem>
                    <MenuItem value="#FFA500">Orange</MenuItem>
                    <MenuItem value="#FFFF00">Yellow</MenuItem>
                  </Select>
                </FormControl>

                <Button variant="contained" onClick={handleUpdateAnnotation}>
                  Save Changes
                </Button>
              </Box>

              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                Comments
              </Typography>
              {selectedShape.comments.map((c) => (
                <Box key={c.id} sx={{ ml: 2, mb: 1 }}>
                  <Typography variant="body2">- {c.text}</Typography>
                </Box>
              ))}

              <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
                <TextField
                  size="small"
                  label="Add Comment"
                  value={newComment}
                  onChange={(e) => setNewComment(e.target.value)}
                  fullWidth
                />
                <Button variant="contained" onClick={handleAddComment}>
                  Add
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </DialogContent>

      <DialogActions>
        <Stack direction="row" spacing={1} sx={{ mr: 'auto', ml: 2 }}>
          <ToggleButtonGroup
            value={currentTool}
            exclusive
            onChange={(e, val) => {
              if (val) {
                setCurrentTool(val);
                setIsDrawing(false);
                setGhostShape(null);
              }
            }}
          >
            <ToggleButton value="select" aria-label="Select Tool">
              <MouseIcon />
            </ToggleButton>
            <ToggleButton value="line" aria-label="Line Tool">
              <StraightenIcon />
            </ToggleButton>
            <ToggleButton value="rect" aria-label="Rect Tool">
              <CropSquareIcon />
            </ToggleButton>
            <ToggleButton value="circle" aria-label="Circle Tool">
              <CircleIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>

        <Stack direction="row" spacing={1}>
          <Button variant="outlined" onClick={handleUndo} startIcon={<UndoIcon />}>
            Undo
          </Button>
          <Button variant="outlined" onClick={handleRedo} startIcon={<RedoIcon />}>
            Redo
          </Button>
          <Button variant="outlined" onClick={handleClearAll} startIcon={<ClearAllIcon />}>
            Clear
          </Button>
          <IconButton onClick={handleZoomOut}>
            <ZoomOutIcon />
          </IconButton>
          <IconButton onClick={handleZoomIn}>
            <ZoomInIcon />
          </IconButton>
          <Button onClick={onClose} variant="contained" color="primary">
            Close
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

export default MeasureAnnotateModal;
