import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Button, Divider } from "@mui/material";
import AssembliPricingSection from "./AssembliPricingSection";
import CustomPricingSection from "./CustomPricingSection";
import Navbar from "../Navbar/Navbar";

function ChooseCost() {
  const [selectedOption, setSelectedOption] = useState(null);
  const navigate = useNavigate();

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    // console.log(event.target.value);
  };

  const handleNext = () => {
    console.log("Selected Option:", selectedOption);
    if(selectedOption === 'assembli'){
      navigate(`/assembli-pricing?selectedOption=${selectedOption}`)
    } else {
      navigate(`/custom-pricing`)
    }
  };

  return (
    <Box
      sx={{
        p: 3,
        marginTop: "64px",
        overflowY: "auto", // Make content scrollable
        position: "relative", // Add this
        zIndex: 0, // Add this to ensure content stays below navbar
      }}
    >
      <Navbar />
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#f9f9f9",
      }}
    >
      <Box
        sx={{
          width: { xs: "90%", sm: "80%", md: "60%" },
          backgroundColor: "#fff",
          padding: "2rem",
          borderRadius: "8px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        {/* Header */}
        <Typography variant="h4" gutterBottom>
          Cost Database Setup
        </Typography>
        <Typography variant="body1" gutterBottom>
          Select if you want to use the Assembli Cost Database or create your own custom pricing model.
        </Typography>
        <Divider sx={{ my: 2 }} />

        {/* Sections */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "stretch",
            gap: "1rem",
          }}
        >
          {/* Assembli Pricing Section */}
          <AssembliPricingSection
            selectedOption={selectedOption}
            handleOptionChange={handleOptionChange}
          />

          {/* Custom Pricing Section */}
          <CustomPricingSection
            selectedOption={selectedOption}
            handleOptionChange={handleOptionChange}
          />
        </Box>

        {/* Action Buttons */}
        <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "2rem" }}>
          <Button variant="outlined">Choose Later</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            disabled={!selectedOption}
          >
            Next
          </Button>
        </Box>
      </Box>
    </Box>
    </Box>
  );
}

export default ChooseCost;
