import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  List,
  ListItem,
  Paper,
  IconButton,
} from "@mui/material";
import {
  Edit as EditIcon,
  Save as SaveIcon,
  Add as AddIcon,
  Remove as RemoveIcon,
} from "@mui/icons-material";

const CompanyOverviewEditor = ({ onSave }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    foundedYear: "[Year]",
    foundingStory: "[Company founding story and journey]",
    missionStatement: "[Company mission statement]",
    coreValues: ["Value 1", "Value 2", "Value 3"],
    milestones: [
      "Year: Achievement 1",
      "Year: Achievement 2",
      "Year: Achievement 3",
    ],
    serviceAreas: ["Area 1", "Area 2", "Area 3", "Area 4"],
  });

  const handleSubmit = () => {
    setIsEditing(false);
    onSave?.(formData);
  };

  const handleInputChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleListItemChange = (field, index, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: prev[field].map((item, i) => (i === index ? value : item)),
    }));
  };

  const handleAddListItem = (field) => {
    setFormData((prev) => ({
      ...prev,
      [field]: [
        ...prev[field],
        field === "serviceAreas" ? "New Area" : "Year: New Achievement",
      ],
    }));
  };

  const handleRemoveListItem = (field, index) => {
    setFormData((prev) => ({
      ...prev,
      [field]: prev[field].filter((_, i) => i !== index),
    }));
  };

  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 3 }}>
        <Button
          variant="contained"
          startIcon={isEditing ? <SaveIcon /> : <EditIcon />}
          onClick={() => (isEditing ? handleSubmit() : setIsEditing(true))}
        >
          {isEditing ? "Save" : "Edit"}
        </Button>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" gutterBottom color="primary">
          Company Story
        </Typography>
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" gutterBottom>
            Founded:
          </Typography>
          {isEditing ? (
            <TextField
              fullWidth
              value={formData.foundedYear}
              onChange={(e) => handleInputChange("foundedYear", e.target.value)}
              size="small"
              sx={{ mb: 1 }}
            />
          ) : (
            <Typography>{formData.foundedYear}</Typography>
          )}
        </Box>
        {isEditing ? (
          <TextField
            fullWidth
            multiline
            rows={4}
            value={formData.foundingStory}
            onChange={(e) => handleInputChange("foundingStory", e.target.value)}
          />
        ) : (
          <Typography>{formData.foundingStory}</Typography>
        )}
      </Box>

      {/* Mission & Values */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" gutterBottom color="primary">
          Mission & Values
        </Typography>
        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle2" gutterBottom>
            Mission Statement:
          </Typography>
          {isEditing ? (
            <TextField
              fullWidth
              multiline
              rows={3}
              value={formData.missionStatement}
              onChange={(e) =>
                handleInputChange("missionStatement", e.target.value)
              }
            />
          ) : (
            <Typography>{formData.missionStatement}</Typography>
          )}
        </Box>

        {/* Core Values */}
        <Box>
          <Typography variant="subtitle2" gutterBottom>
            Core Values:
          </Typography>
          <List>
            {formData.coreValues.map((value, index) => (
              <ListItem
                key={index}
                sx={{ py: 0 }}
                secondaryAction={
                  isEditing && (
                    <IconButton
                      edge="end"
                      size="small"
                      onClick={() => handleRemoveListItem("coreValues", index)}
                    >
                      <RemoveIcon />
                    </IconButton>
                  )
                }
              >
                {isEditing ? (
                  <TextField
                    fullWidth
                    size="small"
                    value={value}
                    onChange={(e) =>
                      handleListItemChange("coreValues", index, e.target.value)
                    }
                  />
                ) : (
                  <Typography>• {value}</Typography>
                )}
              </ListItem>
            ))}
          </List>
          {isEditing && (
            <Button
              startIcon={<AddIcon />}
              onClick={() => handleAddListItem("coreValues")}
              sx={{ mt: 1 }}
            >
              Add Value
            </Button>
          )}
        </Box>
      </Box>

      {/* Growth Milestones and Service Areas */}
      <Box sx={{ display: "flex", gap: 4 }}>
        <Box sx={{ flex: 1 }}>
          <Typography variant="h6" gutterBottom color="primary">
            Growth Milestones
          </Typography>
          <List>
            {formData.milestones.map((milestone, index) => (
              <ListItem
                key={index}
                sx={{ py: 0 }}
                secondaryAction={
                  isEditing && (
                    <IconButton
                      edge="end"
                      size="small"
                      onClick={() => handleRemoveListItem("milestones", index)}
                    >
                      <RemoveIcon />
                    </IconButton>
                  )
                }
              >
                {isEditing ? (
                  <TextField
                    fullWidth
                    size="small"
                    value={milestone}
                    onChange={(e) =>
                      handleListItemChange("milestones", index, e.target.value)
                    }
                  />
                ) : (
                  <Typography>{milestone}</Typography>
                )}
              </ListItem>
            ))}
          </List>
          {isEditing && (
            <Button
              startIcon={<AddIcon />}
              onClick={() => handleAddListItem("milestones")}
              sx={{ mt: 1 }}
            >
              Add Milestone
            </Button>
          )}
        </Box>

        <Box sx={{ flex: 1 }}>
          <Typography variant="h6" gutterBottom color="primary">
            Service Areas
          </Typography>
          <List>
            {formData.serviceAreas.map((area, index) => (
              <ListItem
                key={index}
                sx={{ py: 0 }}
                secondaryAction={
                  isEditing && (
                    <IconButton
                      edge="end"
                      size="small"
                      onClick={() =>
                        handleRemoveListItem("serviceAreas", index)
                      }
                    >
                      <RemoveIcon />
                    </IconButton>
                  )
                }
              >
                {isEditing ? (
                  <TextField
                    fullWidth
                    size="small"
                    value={area}
                    onChange={(e) =>
                      handleListItemChange(
                        "serviceAreas",
                        index,
                        e.target.value
                      )
                    }
                  />
                ) : (
                  <Typography>• {area}</Typography>
                )}
              </ListItem>
            ))}
          </List>
          {isEditing && (
            <Button
              startIcon={<AddIcon />}
              onClick={() => handleAddListItem("serviceAreas")}
              sx={{ mt: 1 }}
            >
              Add Service Area
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default CompanyOverviewEditor;
