// src/components/Authentication/ForgotPassword.js

import React, { useState } from 'react';
import { TextField, Button, Typography, Card, CardContent, Link } from '@mui/material';
import { forgotPassword } from '../../services/userService';
import Navbar from '../Navbar/Navbar';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    try {
      const response = await forgotPassword(email);
      setMessage(response.message || 'Check your email for a password reset link.');
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to send reset link. Please try again.');
    }
  };

  return (
    <div>
      <Navbar />
      <Card sx={{ maxWidth: 400, margin: 'auto', mt: 10 }}>
        <CardContent>
          <Typography variant="h5" gutterBottom>Forgot Password</Typography>
          <form onSubmit={handleForgotPassword}>
            <TextField
              label="Email"
              fullWidth
              margin="normal"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <Button variant="contained" type="submit" fullWidth sx={{ mt: 2 }}>
              Send Reset Link
            </Button>
          </form>
          {message && <Typography sx={{ mt: 2, color: 'green' }}>{message}</Typography>}
          {error && <Typography sx={{ mt: 2, color: 'red' }}>{error}</Typography>}

          <Typography variant="body2" align="center" sx={{ mt: 2 }}>
            <Link href="/login" underline="hover" className="text-blue-600 hover:text-blue-800">
              Back to Login
            </Link>
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
}

export default ForgotPassword;
