import React from 'react';
import { Box, Typography, Avatar } from '@mui/material';
import { Chat as ChatIcon } from '@mui/icons-material';

const ChatPreview = React.memo(function ChatPreview({ onOpen }) {
  return (
    <Box
      sx={{
        border: '1px solid #ccc',
        borderRadius: 2,
        backgroundColor: 'white',
        boxShadow: 3,
        overflow: 'hidden',
        cursor: 'pointer',
        p: 2,
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        width: '100%',
        height: '200px',
        marginTop: 4,
      }}
      onClick={onOpen}
    >
      <Avatar sx={{ bgcolor: 'primary.main' }}>AI</Avatar>
      <Box sx={{ flex: 1 }}>
        <Typography variant="body1" sx={{ fontWeight: 600 }}>
          AI Assistant
        </Typography>
        <Typography variant="caption" color="textSecondary">
          Hello, how can I assist you today?
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: 'primary.main',
          borderRadius: '50%',
          p: 1,
          boxShadow: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ChatIcon sx={{ color: 'white', fontSize: 20 }} />
      </Box>
    </Box>
  );
});

export default ChatPreview;
