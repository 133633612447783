import {
  createSlice,
  createAsyncThunk,
  configureStore,
} from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const takeoffSlice = createSlice({
  name: "takeoffData",
  initialState: {
    data: [],
    loading: false,
    error: null,
    proposalId: null,
  },
  reducers: {
    updateTakeoffData: (state, action) => {
      const { id, updates } = action.payload;
      const item = state.data.find((item) => item.id === id);
      if (item) {
        Object.assign(item, updates);
      }
    },
    setproposalId: (state, action) => {
      state.proposalId = action.payload;
    },
  },
  //   extraReducers: (builder) => {
  //     builder
  //       .addCase(fetchTakeoffData.pending, (state) => {
  //         state.loading = true;
  //       })
  //       .addCase(fetchTakeoffData.fulfilled, (state, action) => {
  //         state.loading = false;
  //         state.data = action.payload;
  //         state.error = null;
  //       })
  //       .addCase(fetchTakeoffData.rejected, (state, action) => {
  //         state.loading = false;
  //         state.error = action.error.message;
  //       });
  //   },
});

// **Proposal Data Slice**
const proposalSlice = createSlice({
  name: "proposalData",
  initialState: {
    data: {}, // JSON object to hold proposal data
  },
  reducers: {
    updateProposalData: (state, action) => {
      state.data = { ...state.data, ...action.payload };
    },
    resetProposalData: (state) => {
      state.data = {};
    },
  },
});

export const { updateTakeoffData, setproposalId } = takeoffSlice.actions;
export const { updateProposalData, resetProposalData } = proposalSlice.actions;

// **Persist Configuration**
const takeoffPersistConfig = {
  key: "takeoffData",
  storage,
  whitelist: ["data", "proposalId"],
};

const proposalPersistConfig = {
  key: "proposalData",
  storage,
  whitelist: ["data"],
};

const persistedTakeoffReducer = persistReducer(
  takeoffPersistConfig,
  takeoffSlice.reducer
);
const persistedProposalReducer = persistReducer(
  proposalPersistConfig,
  proposalSlice.reducer
);

// **Store Configuration**
export const store = configureStore({
  reducer: {
    takeoffData: persistedTakeoffReducer,
    proposalData: persistedProposalReducer,
  },
});

export const persistor = persistStore(store);
