import React, { useCallback } from 'react';
import { Drawer, Box, Typography, IconButton, Card, CardHeader, Divider, CardContent, Grid, CardActions, Button, CircularProgress } from '@mui/material';
import { Close as CloseIcon, Home as HomeIcon, SquareFoot as SquareFootIcon, Bed as BedIcon, Bathroom as BathroomIcon } from '@mui/icons-material';

const SnapshotDrawer = React.memo(function SnapshotDrawer({ open, onClose, snapshotData, initialVisit, acceptedSnapshot, setAcceptedSnapshot }) {
  const renderSnapshotCard = useCallback(() => {
    return (
      <Card variant="outlined" sx={{ borderRadius: 2 }}>
        <CardHeader
          avatar={<HomeIcon color="primary" />}
          title="Home Summary"
          titleTypographyProps={{ variant: 'h6', fontWeight: 'bold' }}
        />
        <Divider />
        <CardContent>
          <Grid container spacing={2} alignItems="flex-start">
            <Grid item xs={12} display="flex" alignItems="center" gap={1}>
              <HomeIcon color="action" />
              <Typography variant="body1">
                <strong>Project Name:</strong> {snapshotData?.project_name || 'N/A'}
              </Typography>
            </Grid>
            <Grid item xs={12} display="flex" alignItems="center" gap={1}>
              <HomeIcon color="action" />
              <Typography variant="body1">
                <strong>Location:</strong> {snapshotData?.location || 'N/A'}
              </Typography>
            </Grid>
            <Grid item xs={12} display="flex" alignItems="center" gap={1}>
              <HomeIcon color="action" />
              <Typography variant="body1">
                <strong>Owner:</strong> {snapshotData?.owner || 'N/A'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} display="flex" alignItems="center" gap={1}>
              <SquareFootIcon color="action" />
              <Typography variant="body1">
                <strong>Area:</strong> {snapshotData?.area_square_feet || 'N/A'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} display="flex" alignItems="center" gap={1}>
              <BedIcon color="action" />
              <Typography variant="body1">
                <strong>Bedrooms:</strong> {snapshotData?.bedrooms || 'N/A'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} display="flex" alignItems="center" gap={1}>
              <BathroomIcon color="action" />
              <Typography variant="body1">
                <strong>Bathrooms:</strong> {snapshotData?.bathrooms || 'N/A'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} display="flex" alignItems="center" gap={1}>
              <HomeIcon color="action" />
              <Typography variant="body1">
                <strong>Home Type:</strong> {snapshotData?.hometype || 'N/A'}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          {initialVisit && !acceptedSnapshot && (
            <Button variant="contained" color="primary" onClick={() => setAcceptedSnapshot(true)}>
              Accept Snapshot
            </Button>
          )}
        </CardActions>
      </Card>
    );
  }, [snapshotData, initialVisit, acceptedSnapshot, setAcceptedSnapshot]);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { width: '700px', maxWidth: '90vw' },
      }}
    >
      <Box sx={{ position: 'relative', height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'primary.main',
            color: 'white',
            p: 1,
          }}
        >
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Snapshot
          </Typography>
          <IconButton onClick={onClose} sx={{ color: 'white' }} aria-label="Close Snapshot Drawer">
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ p: 2, overflowY: 'auto', flexGrow: 1 }}>
          {snapshotData ? renderSnapshotCard() : (
            <Box sx={{ textAlign: 'center', mt: 4 }}>
              <Typography variant="body1">Loading snapshot details...</Typography>
              <CircularProgress />
            </Box>
          )}
        </Box>
      </Box>
    </Drawer>
  );
});

export default SnapshotDrawer;
