import React, { useCallback } from 'react';
import { TableRow, TableCell, IconButton, TextField } from '@mui/material';
import { Delete as DeleteIcon, DragHandle as DragHandleIcon } from '@mui/icons-material';

const SubdivisionRow = React.memo(function SubdivisionRow({
  editMode,
  subdivision,
  subKey,
  divisionKey,
  handleSubdivisionChange,
  handleDeleteSubdivision,
  onDragStart,
  onDragOver,
  onDrop,
  onImagesClick,
}) {
  const handleNameClick = useCallback(() => {
    if (subdivision.images && subdivision.images.length > 0) {
      onImagesClick(subdivision.images);
    }
  }, [subdivision, onImagesClick]);

  // For numeric fields, enforce 2 decimals and no negatives for waste factor.
  const handleWasteFactorChange = useCallback((e) => {
    let val = e.target.value;
    if (!val) {
      handleSubdivisionChange(divisionKey, subKey, 'waste factor', '');
      return;
    }

    if (parseFloat(val) < 0) {
      alert('Waste factor cannot be negative.');
      return;
    }

    // limit to 2 decimals
    if (val.includes('.')) {
      const parts = val.split('.');
      if (parts[1].length > 2) {
        parts[1] = parts[1].substring(0, 2);
        val = parts.join('.');
      }
    }

    // Optionally add % sign in the UI? For example:
    // handleSubdivisionChange(divisionKey, subKey, 'waste factor', val + '%');
    // Or keep numeric, let server handle it. For demonstration, we keep it numeric:
    handleSubdivisionChange(divisionKey, subKey, 'waste factor', val);
  }, [divisionKey, subKey, handleSubdivisionChange]);

  const handleUnitCostChange = useCallback((e) => {
    let val = e.target.value;
    if (!val) {
      handleSubdivisionChange(divisionKey, subKey, 'unit cost', '');
      return;
    }

    if (val.includes('.')) {
      const parts = val.split('.');
      if (parts[1].length > 2) {
        parts[1] = parts[1].substring(0, 2);
        val = parts.join('.');
      }
    }

    handleSubdivisionChange(divisionKey, subKey, 'unit cost', val);
  }, [divisionKey, subKey, handleSubdivisionChange]);

  const handleQuantityChange = useCallback((e) => {
    let val = e.target.value;
    if (!val) {
      handleSubdivisionChange(divisionKey, subKey, 'quantity', '');
      return;
    }

    if (val.includes('.')) {
      const parts = val.split('.');
      if (parts[1].length > 2) {
        parts[1] = parts[1].substring(0, 2);
        val = parts.join('.');
      }
    }

    handleSubdivisionChange(divisionKey, subKey, 'quantity', val);
  }, [divisionKey, subKey, handleSubdivisionChange]);

  return (
    <TableRow
      key={subKey}
      draggable={editMode}
      onDragStart={onDragStart}
      onDragOver={onDragOver}
      onDrop={onDrop}
      sx={{ cursor: editMode ? 'grab' : 'default' }}
    >
      {editMode && (
        <TableCell>
          <IconButton
            sx={{ cursor: 'grab' }}
            onDragStart={(e) => e.stopPropagation()}
            aria-label="Drag Subdivision"
          >
            <DragHandleIcon />
          </IconButton>
        </TableCell>
      )}
      <TableCell
        onClick={!editMode ? handleNameClick : undefined}
        sx={{
          cursor:
            !editMode && subdivision.images && subdivision.images.length > 0
              ? 'pointer'
              : 'default',
        }}
      >
        {editMode ? (
          <TextField
            variant="outlined"
            size="small"
            value={subdivision.name}
            onChange={(e) => handleSubdivisionChange(divisionKey, subKey, 'name', e.target.value)}
          />
        ) : (
          subdivision.name
        )}
      </TableCell>
      <TableCell>
        {editMode ? (
          <TextField
            type="number"
            variant="outlined"
            size="small"
            value={subdivision.quantity}
            onChange={handleQuantityChange}
          />
        ) : (
          subdivision.quantity
        )}
      </TableCell>
      <TableCell>
        {editMode ? (
          <TextField
            variant="outlined"
            size="small"
            value={subdivision.units}
            onChange={(e) => handleSubdivisionChange(divisionKey, subKey, 'units', e.target.value)}
          />
        ) : (
          subdivision.units
        )}
      </TableCell>
      <TableCell>
        {editMode ? (
          <TextField
            type="number"
            variant="outlined"
            size="small"
            value={subdivision['unit cost']}
            onChange={handleUnitCostChange}
            inputProps={{
              step: '0.01',
            }}
          />
        ) : (
          subdivision['unit cost']
        )}
      </TableCell>
      <TableCell>
        {/* Make total cost read-only if the server calculates it */}
        {editMode ? (
          <TextField
            variant="outlined"
            size="small"
            value={subdivision['total cost']}
            disabled
          />
        ) : (
          subdivision['total cost']
        )}
      </TableCell>
      <TableCell>
        {editMode ? (
          <TextField
            type="number"
            variant="outlined"
            size="small"
            value={subdivision['waste factor'].replace('%', '')} // remove % if stored
            onChange={handleWasteFactorChange}
            inputProps={{
              step: '0.01',
              min: 0,
            }}
          />
        ) : (
          subdivision['waste factor']
        )}
      </TableCell>
      {editMode && (
        <TableCell>
          <IconButton
            onClick={() => handleDeleteSubdivision(divisionKey, subKey)}
            aria-label="Delete Subdivision"
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      )}
    </TableRow>
  );
});

export default SubdivisionRow;
