// editors/LicenseEditor.js
import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  List,
  ListItem,
  Paper,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import {
  Edit as EditIcon,
  Save as SaveIcon,
  Add as AddIcon,
  Remove as RemoveIcon,
} from "@mui/icons-material";

const LicenseEditor = ({ onSave }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    licenseNumber: "ABC123456",
    licenseType: "General Contractor",
    expirationDate: "2024-12-31",
    insuranceProvider: "Example Insurance Co",
    insurancePolicies: [
      {
        type: "General Liability",
        coverage: "$2,000,000",
        policyNumber: "GL123456",
      },
      {
        type: "Workers Compensation",
        coverage: "$1,000,000",
        policyNumber: "WC789012",
      },
    ],
    certifications: [
      "LEED Certified Builder",
      "Energy Star Partner",
      "Green Building Professional",
    ],
  });

  const handleSubmit = () => {
    setIsEditing(false);
    onSave?.(formData);
  };

  const handleInputChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handlePolicyChange = (index, field, value) => {
    setFormData((prev) => ({
      ...prev,
      insurancePolicies: prev.insurancePolicies.map((policy, i) =>
        i === index ? { ...policy, [field]: value } : policy
      ),
    }));
  };

  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 3 }}>
        <Button
          variant="contained"
          startIcon={isEditing ? <SaveIcon /> : <EditIcon />}
          onClick={() => (isEditing ? handleSubmit() : setIsEditing(true))}
        >
          {isEditing ? "Save" : "Edit"}
        </Button>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" gutterBottom color="primary">
          License Information
        </Typography>
        <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
          {isEditing ? (
            <>
              <TextField
                label="License Number"
                value={formData.licenseNumber}
                onChange={(e) =>
                  handleInputChange("licenseNumber", e.target.value)
                }
                size="small"
                fullWidth
              />
              <TextField
                label="License Type"
                value={formData.licenseType}
                onChange={(e) =>
                  handleInputChange("licenseType", e.target.value)
                }
                size="small"
                fullWidth
              />
              <TextField
                label="Expiration Date"
                type="date"
                value={formData.expirationDate}
                onChange={(e) =>
                  handleInputChange("expirationDate", e.target.value)
                }
                size="small"
                fullWidth
              />
            </>
          ) : (
            <Typography>
              {formData.licenseType} License #{formData.licenseNumber}
              <br />
              Expires: {formData.expirationDate}
            </Typography>
          )}
        </Box>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" gutterBottom color="primary">
          Insurance Policies
        </Typography>
        <List>
          {formData.insurancePolicies.map((policy, index) => (
            <ListItem
              key={index}
              sx={{ flexDirection: "column", alignItems: "stretch" }}
            >
              {isEditing ? (
                <Box sx={{ display: "flex", gap: 2 }}>
                  <TextField
                    label="Type"
                    value={policy.type}
                    onChange={(e) =>
                      handlePolicyChange(index, "type", e.target.value)
                    }
                    size="small"
                    fullWidth
                  />
                  <TextField
                    label="Coverage"
                    value={policy.coverage}
                    onChange={(e) =>
                      handlePolicyChange(index, "coverage", e.target.value)
                    }
                    size="small"
                    fullWidth
                  />
                  <TextField
                    label="Policy Number"
                    value={policy.policyNumber}
                    onChange={(e) =>
                      handlePolicyChange(index, "policyNumber", e.target.value)
                    }
                    size="small"
                    fullWidth
                  />
                </Box>
              ) : (
                <Typography>
                  {policy.type} - {policy.coverage}
                  <br />
                  Policy #{policy.policyNumber}
                </Typography>
              )}
            </ListItem>
          ))}
        </List>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" gutterBottom color="primary">
          Certifications
        </Typography>
        <List>
          {formData.certifications.map((cert, index) => (
            <ListItem key={index}>
              {isEditing ? (
                <TextField
                  fullWidth
                  value={cert}
                  onChange={(e) => {
                    const newCerts = [...formData.certifications];
                    newCerts[index] = e.target.value;
                    handleInputChange("certifications", newCerts);
                  }}
                  size="small"
                />
              ) : (
                <Typography>• {cert}</Typography>
              )}
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default LicenseEditor;
