import axios from "axios";
import apiAxios from "./axiosConfig";

export const fetchAssembliCostData = async () => {
  try {
    const response = await apiAxios.get("/cost_catalog_backend/assembli_pricing");
    return response.data;
  } catch (error) {
    console.error("Error fetching Assembli cost data:", error.response?.data);
    if (error.response && error.response.data) {
      return { error: error.response.data.error, details: error.response.data.details };
    } else {
      return { error: "An unexpected error occurred.", details: error.message };
    }
  }
};

export const fetchUserPricingData = async () => {
  try {
    const response = await apiAxios.get("/cost_catalog_backend/user_pricing");
    // console.log(response.data)
    return response.data;
  } catch (error) {
    console.error("Error fetching user pricing data:", error.response?.data || error.message);

    if (error.response) {
      // If backend returns an error message
      return { error: error.response.data?.error || "Error fetching user pricing data." };
    } else if (error.request) {
      return { error: "Network error. Please check your connection." };
    } else {
      // Other unexpected errors
      return { error: "An unexpected error occurred while fetching data." };
    }
  }
};

export const generateSignedURLsForFiles = async (files) => {
  try {
    const signedUrlPromises = files.map(async (file) => {
      const payload = {
        fileName: file.name,
        fileType: file.type,
      };
      console.log(`Generating singed url for file = ${file.name}\nPayload = ${JSON.stringify(payload)}`);
      const response = await apiAxios.post("/cost_catalog_backend/generate_signed_url", payload);
      console.log(`Generated Signed URL for\nfile = ${response.data.data.uploadedPath}`);
      if(response.status === 200){
        return {
          file,
          signedUrl: response.data.data.signedUrl,
          uploadedPath: response.data.data.uploadedPath,
          uploadedFileID: response.data.data.uploadedFileID,
        };
      } else{
        throw new Error(response.data.error || "Failed to generate signed URL.");
      }
      
    });

    return await Promise.all(signedUrlPromises);
  } catch (error) {
    console.error("Error generating signed URLs:", error.response?.data || error.message);

    if (error.response) {
      throw new Error(error.response.data.error || "Backend error occurred.");
    } else if (error.request) {
      throw new Error("Network error. Please check your connection.");
    } else {
      throw new Error("An unexpected error occurred.");
    }
  }
};

export const uploadFiles = async (signedUrls) => {
  try {
    const uploadPromises = signedUrls.map(async ({ file, signedUrl, uploadedPath, uploadedFileID }) => {
      console.log(`Uploading file: ${file.name} to ${uploadedPath}`);
      // Upload file using axios
      await axios.put(signedUrl, file, {
        headers: { "Content-Type": file.type }, // Match Content-Type with file type
      });
      console.log(`File uploaded successfully: ${file.name}`);
      // If the upload succeeds, return the uploadedPath
      return { 'uploadedPath': uploadedPath, 'uploadedFileID': uploadedFileID };
    });

    // Resolve all uploads
    return await Promise.all(uploadPromises);
  } catch (error) {
    console.error("Error uploading files:", error.message);
    return {
      error: "Failed to upload files. Please check your network or try again.",
    };
  }
};


export const processCostFilesToExtractData = async (paths) => {
  try {
    const payload = { files: paths };
    console.log("Processing files with payload:", payload);
    const response = await apiAxios.post("/cost_catalog_backend/process_cost_files", payload);
    if (response.status === 200) {
      console.log("Files processed successfully:");
      return response.data; // Success response
    } else {
      console.error("Error processing files:", response.data);
      throw new Error(response.data.error || "Failed to process files.");
    }
  } catch (error) {
    console.error("Unexpected error processing files:", error.response?.data || error.message);
    if (error.response) {
      throw new Error(error.response.data?.error || "Backend error occurred.");
    } else if (error.request) {
      throw new Error("Network error. Please check your connection.");
    } else {
      throw new Error("An unexpected error occurred.");
    }
  }
};

export const updateFileStatus = async (file_id, status) => {
  try {
    const payload = { new_status: status };
    const response = await apiAxios.post(
      `cost_catalog_backend/update_file_status/${file_id}`,
      payload
    );
    if (response.status === 200) {
      console.log("File status updated successfully:");
      return response; // Success
    } else {
      console.error("Error updating file status:", response.data);
      throw new Error(response.data.error || "Failed to update file status.");
    }
  } catch (error) {
    console.error("Unexpected error updating file status:", error.response?.data || error.message);
    if (error.response) {
      throw new Error(error.response.data?.error || "Backend error occurred.");
    } else if (error.request) {
      throw new Error("Network error. Please check your connection.");
    } else {
      throw new Error("An unexpected error occurred.");
    }
  }
};
