// src/components/Profile/ProfileSettings.js
import React, { useState, useContext, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Card,
  CardContent,
  Paper,
  CircularProgress
} from '@mui/material';
import MainLayout from '../MainLayout';
import { AuthContext } from '../../context/authContext';
import { useNavigate } from 'react-router-dom';
import { getCurrentUser, updateCurrentUser } from '../../services/userService';

function ProfileSettings() {
  const { isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  const [currentUser, setCurrentUser] = useState(null);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [loadingCurrentUser, setLoadingCurrentUser] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (!isAuthenticated) return;
      setLoadingCurrentUser(true);
      setErrorMessage('');
      try {
        const userData = await getCurrentUser();
        setCurrentUser(userData);
        setUsername(userData.username || '');
        setEmail(userData.email || '');
      } catch (error) {
        setErrorMessage('Failed to load user details. Please refresh or contact support.');
      } finally {
        setLoadingCurrentUser(false);
      }
    };
    fetchUserDetails();
  }, [isAuthenticated]);

  const handleProfileSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');

    if (password && password !== confirmPassword) {
      setErrorMessage('Passwords do not match.');
      return;
    }

    try {
      const response = await updateCurrentUser({ username, email, password });
      // If email changed, the response may indicate checking email for confirmation
      setSuccessMessage(response.message || 'Profile updated successfully!');
    } catch (error) {
      setErrorMessage(error.response?.data?.error || 'Failed to update profile. Please try again.');
    }
  };

  if (!isAuthenticated) {
    return (
      <MainLayout>
        <Box sx={{ textAlign: 'center', marginTop: 10 }}>
          <Typography variant="h6">You must be logged in to view this page.</Typography>
        </Box>
      </MainLayout>
    );
  }

  if (loadingCurrentUser) {
    return (
      <MainLayout>
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
          <CircularProgress />
        </Box>
      </MainLayout>
    );
  }

  if (!currentUser) {
    return (
      <MainLayout>
        <Box sx={{ textAlign: 'center', marginTop: 10 }}>
          <Typography variant="h6" color="error">
            {errorMessage || 'Failed to load user details.'}
          </Typography>
        </Box>
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <Box sx={{ maxWidth: '1200px', margin: 'auto', padding: 2, marginLeft: 35 }}>
        <Typography variant="h4" gutterBottom>
          Profile Settings
        </Typography>

        {errorMessage && (
          <Paper sx={{ padding: 2, marginBottom: 2 }}>
            <Typography variant="body1" color="error">
              {errorMessage}
            </Typography>
          </Paper>
        )}

        {successMessage && (
          <Paper sx={{ padding: 2, marginBottom: 2 }}>
            <Typography variant="body1" sx={{ color: 'green' }}>
              {successMessage}
            </Typography>
          </Paper>
        )}

        <Card sx={{ marginBottom: 4 }}>
          <CardContent>
            <form onSubmit={handleProfileSubmit}>
              <TextField
                label="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                margin="normal"
                type="email"
              />
              <TextField
                label="New Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
                margin="normal"
                type="password"
              />
              <TextField
                label="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                fullWidth
                margin="normal"
                type="password"
              />
              <Button variant="contained" color="primary" type="submit" sx={{ mt: 2 }}>
                Save Changes
              </Button>
            </form>
          </CardContent>
        </Card>
      </Box>
    </MainLayout>
  );
}

export default ProfileSettings;
