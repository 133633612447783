import React, { useState, useMemo } from "react";
import cloneDeep from "lodash/cloneDeep";

import {
  Card,
  CardHeader,
  CardContent,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Typography,
  Collapse,
  Box,
} from "@mui/material";
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  GridView as GridViewIcon,
  AttachMoney as MoneyIcon,
  Inventory as InventoryIcon,
  Settings as SettingsIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  Tune as TuneIcon,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";

// Utility functions that shape data & create final export
import {
  normalizeTakeOffData,
  generateSettings,
  createExportData,
} from "../utils/takeOffExport";

let finalJsonData = null;

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  "&.active": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

/**
 * @param {Object} props
 * @param {Object} props.takeOffData - The portion of proposal data containing takeOff + settings
 * @param {Function} props.onSave - Callback that receives the updated settings
 */
const ConstructionDataViewer = ({ takeOffData, onSave }) => {
  // 1) Normalize the measurement data
  const divisionData = useMemo(() => {
    return normalizeTakeOffData(takeOffData.takeOff);
  }, [takeOffData]);

  // 2) Create default settings if none exist
  const defaultSettings = generateSettings(divisionData);

  // 3) Existing settings from the parent, or empty
  const existingSettings =
    takeOffData.settings && Object.keys(takeOffData.settings).length > 0
      ? takeOffData.settings
      : {};

  // 4) Local state: never mutate existingSettings directly
  const [settings, setSettings] = useState(() => {
    if (Object.keys(existingSettings).length > 0) {
      return {
        ...existingSettings,
        activePreset: existingSettings.activePreset || "standard",
        expandedDivisions: existingSettings.expandedDivisions || {},
      };
    }
    // Otherwise, fallback to default
    return {
      ...defaultSettings,
      activePreset: "standard",
      expandedDivisions: {},
    };
  });

  const { activePreset, expandedDivisions } = settings;

  // Utility safe parsing
  const safeNumber = (val) => {
    if (typeof val === "number") return val;
    if (typeof val === "string") return parseFloat(val) || 0;
    return 0;
  };
  const safeString = (val) => (typeof val === "string" ? val : "");

  const calculateDivisionTotal = (division) => {
    if (!division?.subdivisions) return 0;
    return Object.values(division.subdivisions).reduce((acc, sub) => {
      return acc + safeNumber(sub["Total Cost"]);
    }, 0);
  };

  const calculateGrandTotal = () => {
    if (!divisionData.divisionOrder) return 0;
    return divisionData.divisionOrder.reduce((acc, divKey) => {
      return acc + calculateDivisionTotal(divisionData[divKey]);
    }, 0);
  };

  // Toggle expansion for a single division
  const handleToggleExpand = (divKey) => {
    setSettings((prev) => {
      const cloned = cloneDeep(prev);
      cloned.expandedDivisions[divKey] = !cloned.expandedDivisions[divKey];
      return cloned;
    });
  };

  // Expand or collapse ALL divisions
  const expandAllDivisions = (shouldExpand) => {
    setSettings((prev) => {
      const cloned = cloneDeep(prev);
      divisionData.divisionOrder.forEach((div) => {
        cloned.expandedDivisions[div] = shouldExpand;
      });
      return cloned;
    });
  };

  // If user toggles columns after picking a built-in preset,
  // we switch them to "custom"
  const switchToCustomIfNeeded = () => {
    setSettings((prev) => {
      if (prev.activePreset === "custom") {
        return prev; // no change
      }
      const cloned = cloneDeep(prev);
      cloned.activePreset = "custom";
      return cloned;
    });
  };

  // "PresetButton" for built-in or custom
  const PresetButton = ({ icon: Icon, label, preset }) => (
    <StyledButton
      variant={activePreset === preset ? "contained" : "outlined"}
      className={activePreset === preset ? "active" : ""}
      startIcon={<Icon />}
      onClick={() => {
        // If user clicks the "custom" button, just switch preset
        if (preset === "custom") {
          setSettings((prev) => {
            const cloned = cloneDeep(prev);
            cloned.activePreset = "custom";
            return cloned;
          });
          return;
        }

        // For built-in presets, override columns
        setSettings((prev) => {
          const newSettingsObject = cloneDeep(prev);
          const newPreset = preset;
          const shouldExpand = newPreset !== "overview";

          divisionData.divisionOrder.forEach((divKey) => {
            if (
              !newSettingsObject[divKey] ||
              !newSettingsObject[divKey].columns
            ) {
              // fallback if not defined
              newSettingsObject[divKey] = {
                columns: {
                  subdivisions: true,
                  quantities: true,
                  units: true,
                  unitCosts: true,
                  totalCosts: true,
                  wasteFactor: true,
                },
              };
            }

            if (newPreset === "standard") {
              newSettingsObject[divKey].columns = {
                subdivisions: true,
                quantities: true,
                units: true,
                unitCosts: true,
                totalCosts: true,
                wasteFactor: true,
              };
            } else if (newPreset === "costs") {
              newSettingsObject[divKey].columns = {
                subdivisions: true,
                quantities: false,
                units: false,
                unitCosts: true,
                totalCosts: true,
                wasteFactor: false,
              };
            } else {
              // "overview"
              newSettingsObject[divKey].columns = {
                subdivisions: false,
                quantities: false,
                units: false,
                unitCosts: false,
                totalCosts: false,
                wasteFactor: false,
              };
            }
          });

          // Expand/collapse
          const allExpanded = {};
          divisionData.divisionOrder.forEach((div) => {
            allExpanded[div] = shouldExpand;
          });

          newSettingsObject.activePreset = newPreset;
          newSettingsObject.expandedDivisions = allExpanded;

          return newSettingsObject;
        });
      }}
    >
      {label}
    </StyledButton>
  );

  // Toggle single column
  const handleColumnToggle = (divKey, columnKey) => {
    switchToCustomIfNeeded();
    setSettings((prev) => {
      const cloned = cloneDeep(prev);
      cloned[divKey].columns[columnKey] = !cloned[divKey].columns[columnKey];
      return cloned;
    });
  };

  // Toggle entire "subdivisions" block
  const handleSubdivisionsToggle = (divKey) => {
    switchToCustomIfNeeded();
    setSettings((prev) => {
      const cloned = cloneDeep(prev);
      const newVal = !cloned[divKey].columns.subdivisions;
      cloned[divKey].columns = {
        subdivisions: newVal,
        quantities: newVal,
        units: newVal,
        unitCosts: newVal,
        totalCosts: newVal,
        wasteFactor: newVal,
      };
      // if subdivisions are hidden, collapse the division
      if (!newVal) {
        cloned.expandedDivisions[divKey] = false;
      }
      return cloned;
    });
  };

  // Save data
  const handleSave = () => {
    finalJsonData = createExportData(divisionData, settings);
    if (onSave) {
      // Pass brand new settings to the parent
      onSave(settings);
    }
  };

  // No data scenario
  if (!divisionData?.divisionOrder?.length) {
    return (
      <Box sx={{ p: 2 }}>
        <Typography>No takeoff data available.</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Card>
        <CardHeader
          title={<Typography gutterBottom>View Presets</Typography>}
          sx={{ pb: 1 }}
        />
        <CardContent sx={{ display: "flex", gap: 1, pt: 0 }}>
          {/* Built-in Presets */}
          <PresetButton
            icon={GridViewIcon}
            label="Standard"
            preset="standard"
          />
          <PresetButton icon={MoneyIcon} label="Costs Only" preset="costs" />
          <PresetButton
            icon={InventoryIcon}
            label="Overview"
            preset="overview"
          />
          {/* "Custom" Preset */}
          <PresetButton icon={TuneIcon} label="Custom" preset="custom" />
        </CardContent>
      </Card>

      <Card>
        <CardContent>
          {divisionData.divisionOrder.map((divKey) => {
            const division = divisionData[divKey] ?? {};
            const divisionSettings = settings[divKey] || {};
            const columns = divisionSettings.columns || {};
            const divisionTotal = calculateDivisionTotal(division);

            return (
              <Paper
                key={divKey}
                elevation={0}
                sx={{
                  border: 1,
                  borderColor: "divider",
                  borderRadius: 1,
                  mb: 2,
                  "&:last-child": { mb: 0 },
                }}
              >
                <Box
                  sx={{
                    p: 2,
                    bgcolor: "grey.50",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <IconButton
                      size="small"
                      onClick={() => handleToggleExpand(divKey)}
                    >
                      {expandedDivisions[divKey] ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </IconButton>
                    <Typography>
                      {divKey} - {division?.name ?? ""}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <Typography
                      variant="subtitle1"
                      color="success.main"
                      fontWeight="bold"
                    >
                      Total: ${divisionTotal.toLocaleString()}
                    </Typography>
                    <IconButton size="small">
                      <SettingsIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </Box>

                <Collapse in={expandedDivisions[divKey]}>
                  <Box sx={{ p: 2 }}>
                    <TableContainer>
                      <Table size="medium">
                        <TableHead>
                          <TableRow sx={{ "& th": { bgcolor: "grey.100" } }}>
                            <TableCell sx={{ width: "20%" }}>
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography variant="subtitle2">
                                  Subdivision
                                </Typography>
                                <Tooltip
                                  title={`${
                                    columns.subdivisions ? "Hide" : "Show"
                                  } Subdivisions`}
                                >
                                  <IconButton
                                    size="small"
                                    onClick={() =>
                                      handleSubdivisionsToggle(divKey)
                                    }
                                    sx={{ ml: 1 }}
                                  >
                                    {columns.subdivisions ? (
                                      <VisibilityIcon
                                        color="primary"
                                        fontSize="small"
                                      />
                                    ) : (
                                      <VisibilityOffIcon
                                        color="action"
                                        fontSize="small"
                                      />
                                    )}
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </TableCell>
                            <TableCell sx={{ width: "10%" }}>
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography variant="subtitle2">
                                  Quantity
                                </Typography>
                                <Tooltip
                                  title={`${
                                    columns.quantities ? "Hide" : "Show"
                                  } Quantity`}
                                >
                                  <IconButton
                                    size="small"
                                    onClick={() =>
                                      handleColumnToggle(divKey, "quantities")
                                    }
                                    sx={{ ml: 1 }}
                                  >
                                    {columns.quantities ? (
                                      <VisibilityIcon
                                        color="primary"
                                        fontSize="small"
                                      />
                                    ) : (
                                      <VisibilityOffIcon
                                        color="action"
                                        fontSize="small"
                                      />
                                    )}
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </TableCell>
                            <TableCell sx={{ width: "10%" }}>
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography variant="subtitle2">
                                  Units
                                </Typography>
                                <Tooltip
                                  title={`${
                                    columns.units ? "Hide" : "Show"
                                  } Units`}
                                >
                                  <IconButton
                                    size="small"
                                    onClick={() =>
                                      handleColumnToggle(divKey, "units")
                                    }
                                    sx={{ ml: 1 }}
                                  >
                                    {columns.units ? (
                                      <VisibilityIcon
                                        color="primary"
                                        fontSize="small"
                                      />
                                    ) : (
                                      <VisibilityOffIcon
                                        color="action"
                                        fontSize="small"
                                      />
                                    )}
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </TableCell>
                            <TableCell sx={{ width: "15%" }}>
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography variant="subtitle2">
                                  Unit Cost ($)
                                </Typography>
                                <Tooltip
                                  title={`${
                                    columns.unitCosts ? "Hide" : "Show"
                                  } Unit Cost`}
                                >
                                  <IconButton
                                    size="small"
                                    onClick={() =>
                                      handleColumnToggle(divKey, "unitCosts")
                                    }
                                    sx={{ ml: 1 }}
                                  >
                                    {columns.unitCosts ? (
                                      <VisibilityIcon
                                        color="primary"
                                        fontSize="small"
                                      />
                                    ) : (
                                      <VisibilityOffIcon
                                        color="action"
                                        fontSize="small"
                                      />
                                    )}
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </TableCell>
                            <TableCell sx={{ width: "15%" }}>
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography variant="subtitle2">
                                  Total Cost ($)
                                </Typography>
                                <Tooltip
                                  title={`${
                                    columns.totalCosts ? "Hide" : "Show"
                                  } Total Cost`}
                                >
                                  <IconButton
                                    size="small"
                                    onClick={() =>
                                      handleColumnToggle(divKey, "totalCosts")
                                    }
                                    sx={{ ml: 1 }}
                                  >
                                    {columns.totalCosts ? (
                                      <VisibilityIcon
                                        color="primary"
                                        fontSize="small"
                                      />
                                    ) : (
                                      <VisibilityOffIcon
                                        color="action"
                                        fontSize="small"
                                      />
                                    )}
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </TableCell>
                            <TableCell sx={{ width: "10%" }}>
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography variant="subtitle2">
                                  Waste Factor
                                </Typography>
                                <Tooltip
                                  title={`${
                                    columns.wasteFactor ? "Hide" : "Show"
                                  } Waste Factor`}
                                >
                                  <IconButton
                                    size="small"
                                    onClick={() =>
                                      handleColumnToggle(divKey, "wasteFactor")
                                    }
                                    sx={{ ml: 1 }}
                                  >
                                    {columns.wasteFactor ? (
                                      <VisibilityIcon
                                        color="primary"
                                        fontSize="small"
                                      />
                                    ) : (
                                      <VisibilityOffIcon
                                        color="action"
                                        fontSize="small"
                                      />
                                    )}
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        {columns.subdivisions && (
                          <TableBody>
                            {division?.subdivisionOrder?.map((subKey) => {
                              const sub = division.subdivisions[subKey] ?? {};
                              const unitCost = safeNumber(sub["Unit Cost"]);
                              const totalCost = safeNumber(sub["Total Cost"]);

                              return (
                                <TableRow key={subKey} hover>
                                  <TableCell sx={{ width: "20%" }}>
                                    {safeString(sub.name)}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      width: "10%",
                                      visibility: columns.quantities
                                        ? "visible"
                                        : "hidden",
                                    }}
                                  >
                                    {safeString(sub.quantity)}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      width: "10%",
                                      visibility: columns.units
                                        ? "visible"
                                        : "hidden",
                                    }}
                                  >
                                    {safeString(sub["Units"])}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      width: "15%",
                                      visibility: columns.unitCosts
                                        ? "visible"
                                        : "hidden",
                                    }}
                                  >
                                    ${unitCost.toLocaleString()}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      width: "15%",
                                      visibility: columns.totalCosts
                                        ? "visible"
                                        : "hidden",
                                    }}
                                  >
                                    ${totalCost.toLocaleString()}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      width: "10%",
                                      visibility: columns.wasteFactor
                                        ? "visible"
                                        : "hidden",
                                    }}
                                  >
                                    {safeString(sub["Waste Factor"])}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        )}
                      </Table>
                    </TableContainer>
                  </Box>
                </Collapse>
              </Paper>
            );
          })}

          {/* Grand Total Section */}
          <Paper
            elevation={0}
            sx={{
              mt: 3,
              p: 2,
              bgcolor: "grey.100",
              border: 1,
              borderColor: "divider",
              borderRadius: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography>Grand Total</Typography>
              <Typography color="success.main" fontWeight="bold">
                ${calculateGrandTotal().toLocaleString()}
              </Typography>
            </Box>
          </Paper>
          <Box sx={{ mt: 3, textAlign: "right" }}>
            <Button variant="contained" color="primary" onClick={handleSave}>
              Save
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ConstructionDataViewer;
export { finalJsonData };
