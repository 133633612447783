export const permissionsDescriptions = {
    'create_users': 'Create Users within the company',
    'company_settings': 'Access Company Settings',
    'create_new_job': 'Create a new job',
    'send_proposals': 'Send proposals to clients',
    'manage_job': 'Manage a job',
    'manage_costs': 'Manage costs for jobs',
    'manage_proposals': 'Manage proposals',
  };
  
  export const rolePermissionsDisplay = {
    'Estimator': ['manage_job', 'manage_costs', 'manage_proposals'],
    'Project Manager': [
      'create_new_job',
      'send_proposals',
      'manage_job',
      'manage_costs',
      'manage_proposals',
    ],
    'Administrator': [
      'create_users',
      'company_settings',
      'create_new_job',
      'send_proposals',
      'manage_job',
      'manage_costs',
      'manage_proposals',
    ],
  };
  