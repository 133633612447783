import { UNSAFE_NavigationContext, useBeforeUnload } from "react-router-dom";
import { useCallback, useContext, useEffect, useState } from "react";

/**
 * useBlocker:
 * - Overrides the navigator's push/replace to "block" them with your custom logic.
 * - If blocked, you can show a confirm dialog or an MUI <Dialog>.
 */
function useBlocker(blocker, when = true) {
  const { navigator } = useContext(UNSAFE_NavigationContext);

  useEffect(() => {
    if (!when) return;

    const originalPush = navigator.push;
    const originalReplace = navigator.replace;

    navigator.push = (...args) => {
      const autoUnblockingTx = {
        retry() {
          blocker(false); // un-block
          navigator.push(...args); // continue
        },
      };
      blocker(autoUnblockingTx);
    };

    navigator.replace = (...args) => {
      const autoUnblockingTx = {
        retry() {
          blocker(false);
          navigator.replace(...args);
        },
      };
      blocker(autoUnblockingTx);
    };

    return () => {
      navigator.push = originalPush;
      navigator.replace = originalReplace;
    };
  }, [blocker, when, navigator]);
}

/**
 * usePrompt:
 * - If "when" is true, intercept route changes and show a custom confirm.
 * - Also handles the beforeunload event for browser/tab close.
 */
export function usePrompt(message, when = true) {
  const [showDialog, setShowDialog] = useState(false);
  const [tx, setTx] = useState(null);

  // Show the custom or native confirm
  const blocker = useCallback((transition) => {
    setTx(transition);
    setShowDialog(true);
  }, []);

  useBlocker(blocker, when);

  // The user tries to close the browser tab?
  useBeforeUnload(
    useCallback(
      (event) => {
        if (when) {
          event.preventDefault();
          event.returnValue = message;
        }
      },
      [when, message]
    )
  );

  const confirmNavigation = useCallback(() => {
    console.log("entered confirm Navigation");
    console.log(tx);
    if (tx) {
      tx.retry();
    }
    setShowDialog(false);
  }, [tx]);

  const cancelNavigation = useCallback(() => {
    setShowDialog(false);
  }, []);

  return { showDialog, confirmNavigation, cancelNavigation, message };
}
