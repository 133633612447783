import React from 'react';
import { Box, Paper, Typography, Avatar } from '@mui/material';

const MessageLeft = React.memo(function MessageLeft({ message, timestamp, avatarDisp }) {
  return (
    <Box sx={{ display: 'flex', mb: 1 }}>
      {avatarDisp && <Avatar sx={{ mr: 1 }}>A</Avatar>}
      <Paper sx={{ p: 1, backgroundColor: 'grey.200', maxWidth: '70%' }}>
        <Typography variant="body2">{message}</Typography>
        {timestamp && (
          <Typography variant="caption" sx={{ display: 'block', textAlign: 'right', mt: 0.5 }}>
            {timestamp}
          </Typography>
        )}
      </Paper>
    </Box>
  );
});

export default MessageLeft;
