import React from "react";
import { Paper, Typography, Radio, FormControlLabel, List, ListItem, ListItemText, Box } from "@mui/material";

function AssembliPricingSection({ selectedOption, handleOptionChange }) {
  return (
    <Paper
      variant="outlined"
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "1rem",
        borderColor: selectedOption === "assembli" ? "primary.main" : "grey.300",
        // opacity: selectedOption === "assembli" || !selectedOption ? 1 : 0.5, // Dim if not selected
        // pointerEvents: selectedOption === "assembli" || !selectedOption ? "auto" : "none", // Disable interaction
      }}
    >
      <Box>
        {/* Radio Button and Title */}
        
        <FormControlLabel
          value="assembli"
          control={
            <Radio
              checked={selectedOption === "assembli"}
              onChange={handleOptionChange}
            />
          }
          label={<Typography variant="h6">Assembli Cost Database</Typography>}
        />

        {/* List Items */}
        <List>
          <ListItem>
            <ListItemText primary="✓ Comes ready to use" />
          </ListItem>
          <ListItem>
            <ListItemText primary="✓ Built-in images and samples for richer prefill" />
          </ListItem>
          <ListItem>
            <ListItemText primary="✓ Pre-trained for better results" />
          </ListItem>
          <ListItem>
            <ListItemText primary="✓ Fully customizable" />
          </ListItem>
          <ListItem>
            <ListItemText primary="✓ Best in industry" />
          </ListItem>
        </List>
      </Box>
    </Paper>
  );
}

export default AssembliPricingSection;
