// src/components/Authentication/ResetUsername.js

import React, { useState } from 'react';
import { TextField, Button, Typography, Card, CardContent } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { resetUsername } from '../../services/userService';
import Navbar from '../Navbar/Navbar';

function ResetUsername() {
  const { token } = useParams();
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [confirmUsername, setConfirmUsername] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleResetUsername = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    if (username !== confirmUsername) {
      setError('Usernames do not match.');
      return;
    }

    try {
      const response = await resetUsername(token, username);
      setMessage(response.message || 'Username updated successfully!');
      setTimeout(() => navigate('/login'), 2000);
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to reset username.');
    }
  };

  return (
    <div>
      <Navbar />
      <Card sx={{ maxWidth: 400, margin: 'auto', mt: 10 }}>
        <CardContent>
          <Typography variant="h5" gutterBottom>Reset Username</Typography>
          <form onSubmit={handleResetUsername}>
            <TextField
              label="New Username"
              fullWidth
              margin="normal"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
            <TextField
              label="Confirm Username"
              fullWidth
              margin="normal"
              value={confirmUsername}
              onChange={(e) => setConfirmUsername(e.target.value)}
              required
            />
            <Button variant="contained" type="submit" fullWidth sx={{ mt: 2 }}>
              Reset Username
            </Button>
          </form>
          {message && <Typography sx={{ mt: 2, color: 'green' }}>{message}</Typography>}
          {error && <Typography sx={{ mt: 2, color: 'red' }}>{error}</Typography>}
        </CardContent>
      </Card>
    </div>
  );
}

export default ResetUsername;
