import React from 'react';
import { Box, TextField, IconButton } from '@mui/material';
import { Send as SendIcon } from '@mui/icons-material';

const TextInput = React.memo(function TextInput({ value, onChange, onSend, disabled }) {
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !disabled) {
      onSend();
    }
  };

  return (
    <Box sx={{ display: 'flex', p: 1, borderTop: '1px solid #ccc' }}>
      <TextField
        fullWidth
        variant="outlined"
        size="small"
        placeholder="Enter your prompt..."
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={handleKeyDown}
        sx={{ mr: 1 }}
        disabled={disabled}
      />
      <IconButton color="primary" onClick={onSend} disabled={disabled}>
        <SendIcon />
      </IconButton>
    </Box>
  );
});

export default TextInput;
