import apiAxios from "./axiosConfig";

export const fetchProposals = async (params) => {
  try {
    const response = await apiAxios.post("/proposal/list", params);
    return response.data;
  } catch (error) {
    console.error("Error fetching proposals:", error);
    throw error;
  }
};

export const fetchActivityLogs = async (params) => {
  try {
    const response = await apiAxios.post("/proposal/logs", params);
    return response.data;
  } catch (error) {
    console.error("Error fetching activity logs:", error);
    throw error;
  }
};

export const duplicateProposal = async (proposalId) => {
  try {
    const response = await apiAxios.post("/proposal/duplicate", {
      proposal_id: proposalId,
    });
    return response.data;
  } catch (error) {
    console.error("Error duplicating proposal:", error);
    throw error;
  }
};

export const createProposal = async (jobId, title, description, tableData) => {
  try {
    // Call the Flask backend using apiAxios
    const response = await apiAxios.post("/proposal/create_proposal", {
      job_id: jobId,
      proposal_name: title,
      custom_message: description,
      tableData: tableData,
    });

    // Return the response data
    return response.data; // Axios automatically parses the JSON response
  } catch (err) {
    console.error(
      "Error creating proposal:",
      err.response?.data || err.message
    );
    return null;
  }
};

export const updateProposal = async (params) => {
  try {
    // Call the Flask backend using apiAxios
    const response = await apiAxios.post("/proposal/update_proposal", {
      params,
    });

    // Return the response data
    return response.data; // Axios automatically parses the JSON response
  } catch (err) {
    console.error(
      "Error updating proposal:",
      err.response?.data || err.message
    );
    return null;
  }
};

export const proposal_inuse_toggle = async (params) => {
  try {
    // Call the Flask backend using apiAxios
    const response = await apiAxios.post("/proposal/proposal_inuse_toggle", {
      params,
    });

    // Return the response data
    return response.data; // Axios automatically parses the JSON response
  } catch (err) {
    if (err.response && err.response.status === 401) {
      // Ignore the 401 Unauthorized error
      console.warn("Ignored 401 Unauthorized error on unmount.");
    } else {
      console.error(
        "Error updating proposal:",
        err.response?.data || err.message
      );
    }
    return null;
  }
};

/**
 * Sends a proposal via email with a PDF attachment.
 * @param {string} shareEmail - The recipient's email address.
 * @param {Blob} pdfBlob - The generated PDF Blob.
 * @returns {object|null} The response data or null if an error occurs.
 */
export const sendProposalEmail = async (shareEmail, pdfBlob) => {
  try {
    // Create FormData
    const formData = new FormData();
    formData.append("pdf_file", pdfBlob, "proposal.pdf");
    formData.append("email", shareEmail);

    // POST to your Flask endpoint
    const response = await apiAxios.post(
      "/proposal/send_proposal_email",
      formData
    );

    // Return the response data (Axios automatically parses JSON)
    return response.data;
  } catch (err) {
    console.error(err.response?.data || err.message);
  }
  return null;
};
