// src/components/SignupInvitation.js

import React, { useState } from 'react';
import { TextField, Button, Typography, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import CardComponent from '../CardComponent';
import AlertMessage from '../AlertMessage';
import Navbar from '../Navbar/Navbar';
import apiAxios from '../../services/axiosConfig';

function SignupInvitation() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);

  // Extract the token from the URL query parameters
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleSignup = async (e) => {
    e.preventDefault();
    setError('');

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      const response = await apiAxios.post('/signup/invitation', {
        token,
        username,
        password,
        confirm_password: confirmPassword,
      });
      if (response.data) {
        navigate('/login');
      }
    } catch (error) {
      setError(error.response?.data?.error || 'Signup failed');
    }
  };

  return (
    <div>
      <Navbar />
      <CardComponent title="Complete Your Signup">
        <form onSubmit={handleSignup} className="space-y-4">
          <TextField
            label="Username"
            variant="outlined"
            fullWidth
            margin="normal"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <TextField
            label="Password"
            type={showPassword ? 'text' : 'password'}
            variant="outlined"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Confirm Password"
            type={showPassword ? 'text' : 'password'}
            variant="outlined"
            fullWidth
            margin="normal"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />

          {/* Password requirements */}
          <div className="mt-2 text-sm text-gray-600">
            <Typography variant="body2" color="text.secondary">
              Password must contain:
              <ul className="list-disc pl-6 mt-1 space-y-1">
                <li>At least 12 characters</li>
                <li>At least one uppercase letter</li>
                <li>At least one lowercase letter</li>
                <li>At least one number</li>
                <li>At least one special character</li>
              </ul>
            </Typography>
          </div>

          <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 3 }}>
            Sign Up
          </Button>
        </form>

        <AlertMessage message={error} type="error" onClose={() => setError('')} />
      </CardComponent>
    </div>
  );
}

export default SignupInvitation;
