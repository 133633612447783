import React from 'react';
import { Box, Typography } from '@mui/material';

const SnapshotMiniPreview = React.memo(function SnapshotMiniPreview({ onOpen, snapshotData }) {
  return (
    <Box
      sx={{
        border: '1px solid #ccc',
        borderRadius: 2,
        boxShadow: 3,
        backgroundColor: 'white',
        cursor: 'pointer',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        width: '100%',
        height: '200px',
        justifyContent: 'center',
        overflow: 'hidden',
      }}
      onClick={onOpen}
    >
      <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
        Snapshot Preview
      </Typography>
      {snapshotData ? (
        <>
          <Typography variant="body2">
            <strong>Project Name:</strong> {snapshotData.project_name || 'N/A'}
          </Typography>
          <Typography variant="body2">
            <strong>Location:</strong> {snapshotData.location || 'N/A'}
          </Typography>
        </>
      ) : (
        <Typography variant="body2" color="textSecondary">
          Loading...
        </Typography>
      )}
    </Box>
  );
});

export default SnapshotMiniPreview;
