import React from 'react';
import { Box, Paper, Typography, Avatar } from '@mui/material';

const MessageRight = React.memo(function MessageRight({ message, timestamp, avatarDisp }) {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 1 }}>
      <Paper sx={{ p: 1, backgroundColor: 'primary.light', maxWidth: '70%' }}>
        <Typography variant="body2" sx={{ color: 'white' }}>
          {message}
        </Typography>
        {timestamp && (
          <Typography
            variant="caption"
            sx={{ display: 'block', textAlign: 'right', mt: 0.5, color: 'white' }}
          >
            {timestamp}
          </Typography>
        )}
      </Paper>
      {avatarDisp && <Avatar sx={{ ml: 1, bgcolor: 'primary.main' }}>U</Avatar>}
    </Box>
  );
});

export default MessageRight;
