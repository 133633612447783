// src/components/Profile/ProfileSidebar.js
import React, { useEffect, useState, useContext } from 'react';
import { Drawer, List, ListItem, ListItemText, Box, CircularProgress, Typography } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/authContext';
import { getIsAdmin } from '../../services/userService';

function ProfileSidebar({ open, onClose }) {
  const { isAuthenticated } = useContext(AuthContext);
  const isPermanent = useMediaQuery('(min-width:769px)');
  const location = useLocation();
  const navigate = useNavigate();

  const [isAdmin, setIsAdmin] = useState(false);
  const [loadingAdmin, setLoadingAdmin] = useState(true);

  useEffect(() => {
    const fetchAdminStatus = async () => {
      try {
        const { is_admin } = await getIsAdmin();
        setIsAdmin(is_admin);
      } catch (error) {
        console.error('Failed to get admin status', error);
      } finally {
        setLoadingAdmin(false);
      }
    };
    if (isAuthenticated) {
      fetchAdminStatus();
    }
  }, [isAuthenticated]);

  const linksForAdmin = [
    { text: 'Profile Settings', path: '/profile' },
    { text: 'Cost Catalogue', path: '/profile/cost-catalogue' },
    { text: 'Create New User', path: '/profile/create-user' },
    { text: 'Manage Users', path: '/profile/manage-users' },
  ];

  const linksForNonAdmin = [
    { text: 'Profile Settings', path: '/profile' },
  ];

  const links = isAdmin ? linksForAdmin : linksForNonAdmin;

  return (
    <Drawer
      variant={isPermanent ? 'permanent' : 'temporary'}
      anchor="left"
      open={open}
      onClose={onClose}
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        '& .MuiDrawer-paper': {
          width: 280,
          boxSizing: 'border-box',
          background: 'linear-gradient(180deg, #f8f9fa 0%, #ffffff 100%)',
          borderRight: '1px solid rgba(0,0,0,0.08)',
        },
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', mt:7 }}>
        {loadingAdmin ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
            <CircularProgress />
          </Box>
        ) : (
          <List sx={{ flexGrow: 1, overflowY: 'auto', px: 1, py: 2 }}>
            {links.map((item, index) => (
              <ListItem
                key={index}
                button
                onClick={() => {
                  navigate(item.path);
                  if (!isPermanent) onClose();
                }}
                selected={location.pathname === item.path}
                sx={{
                  mb: 0.5,
                  borderRadius: '8px',
                  '&.Mui-selected': {
                    backgroundColor: 'primary.lighter',
                    '&:hover': {
                      backgroundColor: 'primary.lighter',
                    },
                  },
                  '&:hover': {
                    backgroundColor: 'rgba(0,0,0,0.04)',
                  },
                }}
              >
                <ListItemText
                  primary={
                    <Typography variant="body2" sx={{ fontWeight: 500 }}>
                      {item.text}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        )}
      </Box>
    </Drawer>
  );
}

export default ProfileSidebar;
