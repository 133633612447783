import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Box,
  Paper,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  IconButton,
  Popover,
  Typography,
  InputAdornment,
  Chip,
  Menu,
  CircularProgress,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  Search as SearchIcon,
  FilterList as FilterListIcon,
  MoreVert as MoreVertIcon,
  ContentCopy as ContentCopyIcon,
  Clear as ClearIcon,
} from "@mui/icons-material";

import {
  fetchProposals,
  duplicateProposal,
  proposal_inuse_toggle,
} from "../../services/proposalService";
import {
  setproposalId,
  updateProposalData,
  resetProposalData,
} from "../../store";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const INITIAL_FILTERS = {
  status: [],
  dateRange: { from: "", to: "" },
};

const STATUS_OPTIONS = ["draft", "sent", "approved", "declined"];

const ProposalsTable = () => {
  // Redux and navigation
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Main data states
  const [proposals, setProposals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [totalRows, setTotalRows] = useState(0);

  // Row selection
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  // Search state
  const [searchParams, setSearchParams] = useState({
    text: "",
    criteria: "proposal_name",
  });
  const [appliedSearchParams, setAppliedSearchParams] = useState({
    text: "",
    criteria: "proposal_name",
  });

  // Filter states
  const [filters, setFilters] = useState(INITIAL_FILTERS);
  const [appliedFilters, setAppliedFilters] = useState(INITIAL_FILTERS);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [activeFilterTab, setActiveFilterTab] = useState("status");

  // Menu and duplication states
  const [menuState, setMenuState] = useState({
    anchorEl: null,
    selectedProposal: null,
  });

  // Dialog states for duplicating
  const [duplicateDialogOpen, setDuplicateDialogOpen] = useState(false);
  const [duplicateData, setDuplicateData] = useState({
    id: null,
    name: "",
  });

  // **NEW**: Dialog for locked proposal
  const [lockedDialogOpen, setLockedDialogOpen] = useState(false);
  const [lockedDialogMessage, setLockedDialogMessage] = useState("");

  // Define columns
  const columns = [
    { field: "user_name", headerName: "User", flex: 1, minWidth: 130 },
    {
      field: "proposal_name",
      headerName: "Proposal Name",
      flex: 1.5,
      minWidth: 180,
    },
    { field: "job_name", headerName: "Job Name", flex: 1.5, minWidth: 180 },
    {
      field: "created_at",
      headerName: "Created On",
      flex: 1,
      minWidth: 120,
      valueGetter: (params) => {
        if (!params) return "Not set";
        return new Date(params).toLocaleDateString("en-US");
      },
    },
    {
      field: "due_date",
      headerName: "Due",
      flex: 1,
      minWidth: 120,
      valueGetter: (params) => {
        if (!params) return "Not set";
        return new Date(params).toLocaleDateString();
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        const status = params?.value || "unknown";
        return (
          <Chip
            label={status.charAt(0).toUpperCase() + status.slice(1)}
            color={status === "approved" ? "success" : "default"}
            size="small"
            sx={{ borderRadius: 2 }}
          />
        );
      },
    },
    {
      field: "total_amount",
      headerName: "Total Amount",
      flex: 1,
      minWidth: 130,
      valueGetter: (params) => {
        if (params == null) return "$0.00";
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(params);
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      minWidth: 100,
      sortable: false,
      renderCell: (params) => (
        <IconButton
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            setMenuState({
              anchorEl: e.currentTarget,
              selectedProposal: params.row,
            });
          }}
        >
          <MoreVertIcon />
        </IconButton>
      ),
    },
  ];

  // Handle the menu actions
  const handleMenuAction = async (action) => {
    const { selectedProposal } = menuState;
    if (!selectedProposal) return;

    if (action === "duplicate") {
      setDuplicateData({
        id: selectedProposal.id,
        name: selectedProposal.proposal_name || "",
      });
      setDuplicateDialogOpen(true);
    }

    setMenuState({ anchorEl: null, selectedProposal: null });
  };

  const handleConfirmDuplicate = async () => {
    try {
      setLoading(true);
      await duplicateProposal({
        id: duplicateData.id,
        name: duplicateData.name,
      });
      await loadProposals();
      setError(null);
    } catch (err) {
      setError("Failed to duplicate proposal");
      console.error("Error:", err);
    } finally {
      setLoading(false);
      setDuplicateDialogOpen(false);
    }
  };

  // Build query parameters
  const queryParams = useMemo(
    () => ({
      search: {
        text: appliedSearchParams.text,
        criteria: appliedSearchParams.criteria,
      },
      filters: {
        status: appliedFilters.status,
        date_range: appliedFilters.dateRange,
      },
      pagination: {
        page: paginationModel.page,
        pageSize: paginationModel.pageSize,
      },
    }),
    [appliedSearchParams, appliedFilters, paginationModel]
  );

  // Fetch proposals
  const loadProposals = useCallback(async () => {
    try {
      setLoading(true);
      const response = await fetchProposals(queryParams);
      setProposals(response.proposals || []);
      setTotalRows(response.total || 0);
      setError(null);
    } catch (err) {
      setError("Failed to load proposals");
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
  }, [queryParams]);

  useEffect(() => {
    loadProposals();
  }, [loadProposals]);

  // Search logic
  const handleSearch = () => {
    if (!searchParams.text.trim()) {
      setAppliedSearchParams((prev) => ({ ...prev, text: "" }));
    } else {
      setAppliedSearchParams(searchParams);
    }
  };

  const handleSearchChange = (field, value) => {
    setSearchParams((prev) => ({ ...prev, [field]: value }));
    if (field === "text" && !value) {
      setAppliedSearchParams((prev) => ({ ...prev, text: "" }));
    }
  };

  const isSearchActive = Boolean(appliedSearchParams.text);

  // Filter logic
  const handleApplyFilters = () => {
    setAppliedFilters(filters);
    setFilterAnchorEl(null);
  };

  const handleClearFilters = () => {
    setFilters(INITIAL_FILTERS);
    setAppliedFilters(INITIAL_FILTERS);
  };

  // On row click, navigate to proposal editor (unless locked)
  const handleRowClick = async (params) => {
    const proposalId = params.row.id;
    try {
      const res = await proposal_inuse_toggle({ id: proposalId });

      if (res.flag) {
        // The proposal is free to edit
        dispatch(setproposalId(proposalId));
        dispatch(resetProposalData());
        dispatch(updateProposalData(params.row.proposal_data));
        navigate(`/proposal_editor`);
      } else {
        // The proposal is locked by another user
        setLockedDialogMessage(
          `Proposal is currently being edited by ${res.username}. Try again later.`
        );
        setLockedDialogOpen(true);
      }
    } catch (err) {
      console.error("Error checking proposal lock status:", err);
    }
  };

  // Status filter
  const handleStatusFilter = (status) => {
    setFilters((prev) => ({
      ...prev,
      status: prev.status.includes(status)
        ? prev.status.filter((s) => s !== status)
        : [...prev.status, status],
    }));
  };

  // Date range filter
  const handleDateRangeChange = (field, value) => {
    setFilters((prev) => ({
      ...prev,
      dateRange: { ...prev.dateRange, [field]: value },
    }));
  };

  // Active filter count for UI
  const getActiveFilterCount = () => {
    return (
      appliedFilters.status.length +
      (appliedFilters.dateRange.from ? 1 : 0) +
      (appliedFilters.dateRange.to ? 1 : 0)
    );
  };

  return (
    <Box sx={{ width: "100%" }}>
      {error && (
        <Alert
          severity="error"
          sx={{ mb: 2, borderRadius: 2 }}
          onClose={() => setError(null)}
        >
          {error}
        </Alert>
      )}

      <Paper
        sx={{
          mb: 3,
          p: 2,
          borderRadius: 2,
          borderLeft: isSearchActive ? 4 : 0,
          borderColor: "primary.main",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: 2,
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", gap: 2, flex: 1 }}>
            <FormControl sx={{ minWidth: 150 }}>
              <InputLabel>Search By</InputLabel>
              <Select
                value={searchParams.criteria}
                onChange={(e) => handleSearchChange("criteria", e.target.value)}
                label="Search By"
                size="small"
                sx={{ borderRadius: 2 }}
              >
                <MenuItem value="proposal_name">Proposal Name</MenuItem>
                <MenuItem value="job_name">Job Name</MenuItem>
                <MenuItem value="user_name">User</MenuItem>
              </Select>
            </FormControl>

            <Box sx={{ display: "flex", flex: 1, gap: 1 }}>
              <TextField
                size="small"
                placeholder={`Search by ${searchParams.criteria.replace(
                  "_",
                  " "
                )}`}
                value={searchParams.text}
                onChange={(e) => handleSearchChange("text", e.target.value)}
                sx={{
                  flex: 1,
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 2,
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: searchParams.text && (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={() => {
                          handleSearchChange("text", "");
                          setAppliedSearchParams((prev) => ({
                            ...prev,
                            text: "",
                          }));
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                variant="contained"
                onClick={handleSearch}
                disabled={!searchParams.text}
                sx={{ borderRadius: 2 }}
              >
                Search
              </Button>
            </Box>
          </Box>

          <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
            <Button
              variant="outlined"
              startIcon={<FilterListIcon />}
              onClick={(e) => setFilterAnchorEl(e.currentTarget)}
              color={getActiveFilterCount() > 0 ? "primary" : "inherit"}
              sx={{ borderRadius: 2 }}
              endIcon={
                getActiveFilterCount() > 0 && (
                  <Chip
                    size="small"
                    label={getActiveFilterCount()}
                    sx={{ ml: 1 }}
                  />
                )
              }
            >
              Filters
            </Button>
          </Box>
        </Box>
      </Paper>

      <Box sx={{ height: 600, width: "100%" }}>
        <DataGrid
          rows={proposals}
          columns={columns}
          pagination
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[5, 10, 25, 50]}
          rowCount={totalRows}
          paginationMode="server"
          disableColumnFilter
          rowSelectionModel={rowSelectionModel}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          onRowClick={handleRowClick}
          loading={loading}
          getRowId={(row) => row.id}
          sx={{
            borderRadius: 2,
            "& .MuiDataGrid-cell": {
              borderColor: "divider",
            },
            ".MuiDataGrid-iconButtonContainer": {
              visibility: "visible",
            },
            ".MuiDataGrid-sortIcon": {
              opacity: "inherit !important",
            },
          }}
        />
      </Box>

      {/* MENU FOR ACTIONS */}
      <Menu
        anchorEl={menuState.anchorEl}
        open={Boolean(menuState.anchorEl)}
        onClose={() => setMenuState({ anchorEl: null, selectedProposal: null })}
        PaperProps={{
          sx: { borderRadius: 2 },
        }}
      >
        <MenuItem
          onClick={() => handleMenuAction("duplicate")}
          sx={{
            borderRadius: 1,
            mx: 0.5,
            "&:hover": {
              backgroundColor: "action.hover",
            },
          }}
        >
          <ContentCopyIcon sx={{ mr: 1, fontSize: 20 }} />
          Duplicate
        </MenuItem>
      </Menu>

      {/* DIALOG FOR DUPLICATING */}
      <Dialog
        open={duplicateDialogOpen}
        onClose={() => setDuplicateDialogOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Duplicate Proposal</DialogTitle>
        <DialogContent sx={{ mt: 1 }}>
          <TextField
            fullWidth
            label="Proposal Name"
            value={duplicateData.name}
            onChange={(e) =>
              setDuplicateData((prev) => ({ ...prev, name: e.target.value }))
            }
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDuplicateDialogOpen(false)}
            sx={{ borderRadius: 2 }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleConfirmDuplicate}
            sx={{ borderRadius: 2 }}
          >
            Duplicate
          </Button>
        </DialogActions>
      </Dialog>

      {/* FILTER POPOVER */}
      <Popover
        open={Boolean(filterAnchorEl)}
        anchorEl={filterAnchorEl}
        onClose={() => setFilterAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            width: 500,
            borderRadius: 2,
          },
        }}
      >
        <Box
          sx={{
            p: 2,
            borderBottom: 1,
            borderColor: "divider",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Filters</Typography>
          <Button
            size="small"
            startIcon={<ClearIcon />}
            onClick={handleClearFilters}
            sx={{ borderRadius: 2 }}
            disabled={
              !(
                filters.status.length > 0 ||
                filters.dateRange.from ||
                filters.dateRange.to
              )
            }
          >
            Clear All
          </Button>
        </Box>
        <Box sx={{ display: "flex", height: 400 }}>
          <Box
            sx={{
              width: "40%",
              borderRight: 1,
              borderColor: "divider",
              p: 2,
            }}
          >
            <Button
              fullWidth
              variant={activeFilterTab === "status" ? "contained" : "text"}
              onClick={() => setActiveFilterTab("status")}
              sx={{
                mb: 1,
                borderRadius: 2,
              }}
            >
              Status
            </Button>
            <Button
              fullWidth
              variant={activeFilterTab === "date" ? "contained" : "text"}
              onClick={() => setActiveFilterTab("date")}
              sx={{ borderRadius: 2 }}
            >
              Created At
            </Button>
          </Box>

          <Box sx={{ width: "60%", p: 2 }}>
            {activeFilterTab === "status" ? (
              <Box>
                {STATUS_OPTIONS.map((status) => (
                  <Box
                    key={status}
                    sx={{
                      p: 2,
                      mb: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      bgcolor: filters.status.includes(status)
                        ? "primary.main"
                        : "background.paper",
                      color: filters.status.includes(status)
                        ? "primary.contrastText"
                        : "text.primary",
                      borderRadius: 2,
                      cursor: "pointer",
                      transition: "all 0.2s",
                      "&:hover": {
                        bgcolor: filters.status.includes(status)
                          ? "primary.dark"
                          : "action.hover",
                      },
                    }}
                    onClick={() => handleStatusFilter(status)}
                  >
                    <Typography sx={{ textTransform: "capitalize" }}>
                      {status}
                    </Typography>
                  </Box>
                ))}
              </Box>
            ) : (
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <TextField
                  label="From"
                  type="date"
                  value={filters.dateRange.from}
                  onChange={(e) =>
                    handleDateRangeChange("from", e.target.value)
                  }
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: 2,
                    },
                  }}
                />
                <TextField
                  label="To"
                  type="date"
                  value={filters.dateRange.to}
                  onChange={(e) => handleDateRangeChange("to", e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: 2,
                    },
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            p: 2,
            borderTop: 1,
            borderColor: "divider",
            display: "flex",
            justifyContent: "flex-end",
            gap: 1,
          }}
        >
          <Button
            onClick={() => setFilterAnchorEl(null)}
            sx={{ borderRadius: 2 }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleApplyFilters}
            sx={{ borderRadius: 2 }}
          >
            Apply Filters
          </Button>
        </Box>
      </Popover>

      {/* LOCKED PROPOSAL DIALOG */}
      <Dialog
        open={lockedDialogOpen}
        onClose={() => setLockedDialogOpen(false)}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>Proposal Locked</DialogTitle>
        <DialogContent>
          <Typography>{lockedDialogMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setLockedDialogOpen(false)}
            variant="contained"
            sx={{ borderRadius: 2 }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ProposalsTable;
