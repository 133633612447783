import React, { useState } from 'react';
import {
  Drawer,
  Box,
  Typography,
  IconButton,
  List,
  ListItem,
  Button
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import MeasureAnnotateModal from './MeasureAnnotateModal';

/**
 * Props:
 *   - open
 *   - onClose
 *   - jobId
 *   - imageUrls (array of strings)
 */
function ImagesDrawer({ open, onClose, jobId, imageUrls = [] }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [activeImage, setActiveImage] = useState(null);

  const handleOpenModal = (imgUrl) => {
    setActiveImage(imgUrl);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setActiveImage(null);
    setModalOpen(false);
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        PaperProps={{ sx: { width: '600px' } }}
      >
        <Box
          sx={{
            p: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Typography variant="h6">Images</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <List>
          {imageUrls.map((imgUrl, idx) => (
            <ListItem
              key={idx}
              sx={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <img
                src={imgUrl}
                alt={`Image #${idx}`}
                style={{
                  width: '100%',
                  maxHeight: '150px',
                  objectFit: 'cover',
                  marginBottom: '8px'
                }}
              />
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Button
                  variant="outlined"
                  onClick={() => handleOpenModal(imgUrl)}
                >
                  Measure / Annotate
                </Button>
              </Box>
            </ListItem>
          ))}
        </List>
      </Drawer>

      {modalOpen && activeImage && (
        <MeasureAnnotateModal
          open={modalOpen}
          onClose={handleCloseModal}
          jobId={jobId}
          imageUrl={activeImage}
          dpi={300}
        />
      )}
    </>
  );
}

export default ImagesDrawer;
