// src/components/Profile/CreateUser.js
import React, { useState, useContext, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import MainLayout from '../MainLayout';
import { AuthContext } from '../../context/authContext';
import { useNavigate } from 'react-router-dom';
import { inviteUser, getIsAdmin } from '../../services/userService';
import { permissionsDescriptions, rolePermissionsDisplay } from '../../constants/permissions';

function CreateUser() {
  const { isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  const [isAdmin, setIsAdmin] = useState(false);
  const [loadingAdminStatus, setLoadingAdminStatus] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [newUserEmail, setNewUserEmail] = useState('');
  const [newUserRole, setNewUserRole] = useState('Estimator');

  const roles = ['Administrator', 'Project Manager', 'Estimator'];

  useEffect(() => {
    if (!isAuthenticated) navigate('/login');
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    const checkAdmin = async () => {
      setLoadingAdminStatus(true);
      try {
        const { is_admin } = await getIsAdmin();
        setIsAdmin(is_admin);
      } catch {
        setErrorMessage('Failed to determine admin status.');
      } finally {
        setLoadingAdminStatus(false);
      }
    };
    if (isAuthenticated) checkAdmin();
  }, [isAuthenticated]);

  const handleCreateUser = async () => {
    if (!newUserEmail.trim()) {
      setErrorMessage('Please enter a valid email address');
      return;
    }
    if (!roles.includes(newUserRole)) {
      setErrorMessage('Invalid role selected.');
      return;
    }
    setErrorMessage('');
    setSuccessMessage('');
    try {
      const response = await inviteUser(newUserEmail, newUserRole);
      if (response && response.message === 'Invitation sent successfully') {
        setSuccessMessage('Invitation sent successfully!');
      }
      setNewUserEmail('');
      setNewUserRole('Estimator');
    } catch (error) {
      setErrorMessage('Error inviting user. Please try again later.');
    }
  };

  const getPermissionsForRole = (role) => {
    return rolePermissionsDisplay[role] || [];
  };

  if (!isAuthenticated) {
    return (
      <MainLayout>
        <Typography variant="h6" sx={{ mt: 10, textAlign: 'center' }}>
          You must be logged in to view this page.
        </Typography>
      </MainLayout>
    );
  }

  if (loadingAdminStatus) {
    return (
      <MainLayout>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
          <CircularProgress />
        </Box>
      </MainLayout>
    );
  }

  if (!isAdmin) {
    return (
      <MainLayout>
        <Box sx={{ textAlign: 'center', mt: 10 }}>
          <Typography variant="h6" color="error">
            You do not have permission to view this page.
          </Typography>
        </Box>
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <Box sx={{ maxWidth: '1200px', margin: 'auto', padding: 2, marginLeft: 35 }}>
        <Typography variant="h5" gutterBottom>
          Invite New User
        </Typography>

        {errorMessage && (
          <Paper sx={{ padding: 2, marginBottom: 2 }}>
            <Typography variant="body1" color="error">
              {errorMessage}
            </Typography>
          </Paper>
        )}

        {successMessage && (
          <Paper sx={{ padding: 2, marginBottom: 2 }}>
            <Typography variant="body1" style={{ color: 'green' }}>
              {successMessage}
            </Typography>
          </Paper>
        )}

        <Card sx={{ marginBottom: 4 }}>
          <CardContent>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginBottom: 2, flexWrap: 'wrap' }}>
              <TextField
                label="User Email"
                value={newUserEmail}
                onChange={(e) => setNewUserEmail(e.target.value)}
                variant="outlined"
                sx={{ flex: '1 1 250px' }}
              />
              <FormControl variant="outlined" sx={{ minWidth: 200 }}>
                <InputLabel>Role</InputLabel>
                <Select
                  value={newUserRole}
                  onChange={(e) => setNewUserRole(e.target.value)}
                  label="Role"
                >
                  {roles.map((role) => (
                    <MenuItem key={role} value={role}>
                      {role}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button variant="contained" color="primary" onClick={handleCreateUser}>
                Invite User
              </Button>
            </Box>

            <Box sx={{ marginBottom: 4 }}>
              <Typography variant="subtitle1">Selected Role Permissions:</Typography>
              <List dense>
                {getPermissionsForRole(newUserRole).length > 0 ? (
                  getPermissionsForRole(newUserRole).map((permName) => (
                    <ListItem key={permName}>
                      <ListItemIcon>
                        <svg style={{color: '#1976d2'}} xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                          <path fill="currentColor" d="M9 16.17L4.83 12 3.41 13.41 9 19 21 7 19.59 5.58z"/>
                        </svg>
                      </ListItemIcon>
                      <ListItemText primary={permissionsDescriptions[permName]} />
                    </ListItem>
                  ))
                ) : (
                  <Typography variant="body2">No permissions assigned.</Typography>
                )}
              </List>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </MainLayout>
  );
}

export default CreateUser;
