import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Collapse,
  TextField,
  Snackbar,
  Alert,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
// import axios from "axios";
import LineItemsTable from "./LineItemsTable";

import { fetchAssembliCostData } from "../../services/pricingService";
import Navbar from "../Navbar/Navbar";

const AssembliPricing = () => {
  const [data, setData] = useState([]);
  const [openDivisions, setOpenDivisions] = useState({});
  const [selectedSubdivision, setSelectedSubdivision] = useState(null);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("description");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filter, setFilter] = useState("");

  const [error, setError] = useState(null); // To store error message
  const [snackbarOpen, setSnackbarOpen] = useState(false); // Snackbar state


  // Fetch data from backend
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchAssembliCostData();
        if (response.error) {
          setError(response.error);
          setSnackbarOpen(true);
        } else {
          setData(response);
          setError(null); // Clear any existing errors
        }
      } catch (error) {
        console.error("Error fetching Assembli pricing data:", error);
        setError("An unexpected error occurred.");
        setSnackbarOpen(true);
      }

    };
    fetchData();
  }, []);

  // Toggle division collapse
  const toggleDivision = (divisionName) => {
    setOpenDivisions((prev) => ({
      ...prev,
      [divisionName]: !prev[divisionName],
    }));
  };

  // Handle subdivision selection
  const handleSubdivisionClick = (subdivision) => {
    setSelectedSubdivision(subdivision);
    setPage(0); // Reset pagination
  };

  // Filter input handler
  const handleFilterChange = (event) => {
    setFilter(event.target.value.toLowerCase());
    setPage(0);
  };

  // Handle sorting
  const handleSort = (column) => {
    const isAsc = orderBy === column && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(column);
  };

  // Filter data dynamically
  const getFilteredItems = () => {
    if (!selectedSubdivision) return [];
    return selectedSubdivision.lineItems.filter(
      (item) =>
        item.description.toLowerCase().includes(filter) ||
        (item.material_cost && item.material_cost.toString().includes(filter)) ||
        (item.labor_cost && item.labor_cost.toString().includes(filter))
    );
  };

  const filteredItems = getFilteredItems();

  // Dynamically generate columns
  const columns = selectedSubdivision
    ? Object.keys(filteredItems[0] || {}).map((key) => ({
        id: key,
        label: key.replace(/_/g, " ").toUpperCase(),
      }))
    : [];

  return (
    <Box
    sx={{
      p: 3,
      marginTop: "64px",
      overflowY: "auto", // Make content scrollable
      position: "relative", // Add this
      zIndex: 0, // Add this to ensure content stays below navbar
    }}
  >
    <Navbar />
    <Box sx={{ display: "flex", height: "100vh" }}>
      {/* Left Panel: Divisions */}
      <Box sx={{ width: "30%", borderRight: "1px solid #ddd", overflowY: "auto" }}>
        <Typography variant="h6" sx={{ padding: "1rem", textAlign: "center" }}>
          Divisions
        </Typography>
        <List>
          {data.map((division) => (
            <React.Fragment key={division.division}>
              <ListItem disablePadding>
                <ListItemButton onClick={() => toggleDivision(division.division)}>
                  <ListItemText primary={division.division} />
                  {openDivisions[division.division] ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
              </ListItem>
              <Collapse in={openDivisions[division.division]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {division.subdivisions.map((subdivision) => (
                    <ListItem key={subdivision.name} sx={{ pl: 4 }} disablePadding>
                      <ListItemButton onClick={() => handleSubdivisionClick(subdivision)}>
                        <ListItemText primary={subdivision.name} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </React.Fragment>
          ))}
        </List>
      </Box>

      {/* Right Panel: Table */}
      <Box sx={{ flexGrow: 1, padding: "1rem" }}>
        <Typography variant="h6" sx={{ paddingBottom: "1rem" }}>
          {selectedSubdivision
            ? `Line Items for ${selectedSubdivision.name}`
            : "Select a Subdivision"}
        </Typography>

        {/* Filter Input */}
        <TextField
          label="Filter Line Items"
          variant="outlined"
          size="small"
          fullWidth
          onChange={handleFilterChange}
          sx={{ marginBottom: "1rem" }}
        />

        {selectedSubdivision && (
          <LineItemsTable
            lineItems={filteredItems}
            columns={columns} // Pass dynamic columns
            order={order}
            orderBy={orderBy}
            handleSort={handleSort}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangePage={(event, newPage) => setPage(newPage)}
            handleChangeRowsPerPage={(event) => setRowsPerPage(+event.target.value)}
          />
        )}
      </Box>
      {/* Snackbar for error messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
    </Box>
    </Box>
  );
};

export default AssembliPricing;
