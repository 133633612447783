import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 30,
    backgroundColor: "white",
  },
  header: {
    marginBottom: 15,
    paddingBottom: 10,
    borderBottom: "1 solid #E5E7EB",
  },
  headerTitle: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#111827",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    gap: 20,
  },
  section: {
    flex: 1,
  },
  sectionTitle: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2563EB",
    marginBottom: 10,
  },
  contentBox: {
    backgroundColor: "#F9FAFB",
    padding: 15,
    borderRadius: 4,
  },
  row: {
    marginBottom: 10,
  },
  label: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#111827",
    marginBottom: 4,
  },
  value: {
    fontSize: 11,
    color: "#374151",
  },
  lastRow: {
    marginBottom: 0,
  },
});

const LicenseInsurancePDF = ({
  licenseNumber,
  validUntil,
  classifications,
  liability,
  workersComp,
  bondCapacity,
}) => (
  <Document>
    {/* Header */}
    <View style={styles.header}>
      <Text style={styles.headerTitle}>License & Insurance Details</Text>
    </View>

    {/* Main content container */}
    <View style={styles.container}>
      {/* Licenses Section */}
      <View style={styles.section}>
        <Text style={styles.sectionTitle}>Licenses</Text>
        <View style={styles.contentBox}>
          <View style={styles.row}>
            <Text style={styles.label}>State License: #{licenseNumber}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.label}>Valid Until: {validUntil}</Text>
          </View>
          <View style={[styles.row, styles.lastRow]}>
            <Text style={styles.label}>Classifications:</Text>
            <Text style={styles.value}>{classifications}</Text>
          </View>
        </View>
      </View>

      {/* Insurance Coverage Section */}
      <View style={styles.section}>
        <Text style={styles.sectionTitle}>Insurance Coverage</Text>
        <View style={styles.contentBox}>
          <View style={styles.row}>
            <Text style={styles.label}>Liability: ${liability}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.label}>Workers Comp:</Text>
            <Text style={styles.value}>{workersComp}</Text>
          </View>
          <View style={[styles.row, styles.lastRow]}>
            <Text style={styles.label}>Bond Capacity: ${bondCapacity}</Text>
          </View>
        </View>
      </View>
    </View>
  </Document>
);

export default LicenseInsurancePDF;
