// src/services/jobService.js

import apiAxios from "./axiosConfig"; // Use the configured Axios instance

// Create a new job
export const createJob = async (prompt, fileUrl) => {
  try {

    const payload = {
      prompt: prompt,
      file_url: fileUrl,
    };

    const response = await apiAxios.post('/jobs', payload);
    return response.data;

  } catch (error) {
    console.error('Error creating job:', error.response?.data);
    // Return an error object to be handled by the caller
    if (error.response && error.response.data) {
      return { error: error.response.data.error };
    } else {
      return { error: 'An unexpected error occurred.' };
    }
  }
};

// Get list of jobs
export const getJobs = async () => {
  try {
    const response = await apiAxios.get('/jobs', {
      // Include authentication headers if needed
      // headers: {
      //   'Authorization': `Bearer ${token}`,
      // },
    });
    return response.data; // Array of jobs
  } catch (error) {
    console.error('Error fetching jobs:', error.response?.data);
    // Return an error object to be handled by the caller
    if (error.response && error.response.data) {
      return { error: error.response.data.error };
    } else {
      return { error: 'Failed to fetch jobs. Please try again.' };
    }
  }
};


export const getSnapshotData = async (jobId) => {
  try {

    const response = await apiAxios.get(`/jobs/${jobId}/snapshot`);

    return response.data;

  } catch (error) {
    // Return an error object to be handled by the caller
    if (error.response && error.response.data) {
      return { error: error.response.data.error };
    } else {
      return { error: 'An unexpected error occurred.' };
    }
  }
};


// Example function to update the takeoff JSON for a job
export const updateTakeoffData = async (jobId, updatedData) => {
  try {
    const response = await apiAxios.put(`/jobs/${jobId}/takeoff`, updatedData);
    return response.data;
  } catch (error) {
    console.error('Error updating takeoff data:', error.response?.data);
    if (error.response && error.response.data) {
      return { error: error.response.data.error };
    } else {
      return { error: 'Failed to update takeoff data. Please try again.' };
    }
  }
};


export const getTakeoffData = async (jobid) => {
  try {
    const response = await apiAxios.get(`/jobs/${jobid}/takeoff`);
    return response.data;
  } catch (error) {
    console.error('Error fetching takeoff data:', error.response?.data);
    if (error.response && error.response.data) {
      return { error: error.response.data.error };
    } else {
      return { error: 'Failed to fetch takeoff data. Please try again.' };
    }
  }
};

// Request a signed policy for file upload
export const requestSignedPolicy = async (projectId, fileName, contentType) => {
  try {
    const response = await apiAxios.post(
      `/projects/${projectId}/upload_policy`,
      {
        file_name: fileName,
        content_type: contentType,
      }
    );
    return response.data; // Contains 'url' and 'fields'
  } catch (error) {
    console.error("Error requesting signed policy:", error.response?.data);
    throw error;
  }
};

// Upload file using the signed policy with progress tracking
export const uploadFileWithPolicy = async (policyData, file, setProgress) => {
  try {
    const formData = new FormData();
    Object.entries(policyData.fields).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append("file", file);

    const response = await apiAxios.post(policyData.url, formData, {
      headers: {
        "Content-Type": "multipart/form-data", // Ensure correct Content-Type
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgress(percentCompleted);
      },
    });

    if (response.status !== 204 && response.status !== 201) {
      throw new Error(`Failed to upload file: ${response.statusText}`);
    }

    return response;
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
};


export const getJobImages = async (jobId) => {
  try {
    const response = await apiAxios.get(`/jobs/${jobId}/images`);
    return response.data; // Contains image_urls
  } catch (error) {
    console.error('Error fetching job images:', error.response?.data);
    throw error;
  }
};


export const getChatMessages = async (jobId, limit = 20, offset = 0) => {
  try {
    const response = await apiAxios.get(`/jobs/${jobId}/chat`, {
      params: { limit, offset },
    });
    // Response now has { messages: [...], total_count: number }
    return response.data;
  } catch (error) {
    console.error('Error fetching chat messages:', error.response?.data);
    return { messages: [], total_count: 0 };
  }
};


export const sendChatMessage = async (jobId, message) => {
  try {
    const response = await apiAxios.post(`/jobs/${jobId}/chat`, { message });
    // response.data now only contains newly created messages (user + assistant)
    return response.data;
  } catch (error) {
    console.error('Error sending chat message:', error.response?.data);
    return [];
  }
};