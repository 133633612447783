/**
 * Normalize the provided takeOffData to ensure consistent keys and structure.
 * @param {Object} takeOffData - The raw takeOff data.
 * @returns {Object} Normalized data.
 */
export const normalizeTakeOffData = (takeOffData) => {
  if (!takeOffData || !takeOffData.divisionOrder) {
    return {
      divisionOrder: [],
    };
  }

  const clonedData = JSON.parse(JSON.stringify(takeOffData));

  clonedData.divisionOrder.forEach((divKey) => {
    const division = clonedData[divKey];
    if (!division?.subdivisionOrder) return;

    division.subdivisionOrder.forEach((subKey) => {
      const sub = division.subdivisions[subKey];
      if (!sub) return;

      // Store the values before deleting
      const unitCost = sub["unit cost"] ?? sub["Unit Cost"] ?? 0;
      const totalCost = sub["total cost"] ?? sub["Total Cost"] ?? 0;

      // Normalize keys (fallback to stored values if missing)
      sub["Units"] = sub["units"] ?? sub["Units"] ?? "";
      sub["Unit Cost"] = unitCost;
      sub["Total Cost"] = totalCost;
      sub["Waste Factor"] = sub["waste factor"] ?? sub["Waste Factor"] ?? "";
      sub["quantity"] = sub["quantity"] ?? "";

      // Remove old keys after we've stored their values
      delete sub["units"];
      delete sub["unit cost"];
      delete sub["total cost"];
      delete sub["waste factor"];
    });
  });

  return clonedData;
};

/**
 * Generate initial settings based on normalized division data.
 * @param {Object} divisionData - The normalized division data.
 * @returns {Object} Initial settings for column visibility.
 */
export const generateSettings = (divisionData) => {
  return Object.fromEntries(
    divisionData.divisionOrder.map((div) => [
      div,
      {
        columns: {
          subdivisions: true,
          quantities: true,
          units: true,
          unitCosts: true,
          totalCosts: true,
          wasteFactor: true,
        },
      },
    ])
  );
};

/**
 * Create exportable data based on the current settings and division data.
 * @param {Object} divisionData - The normalized division data.
 * @param {Object} settings - The current column visibility settings.
 * @returns {Object} Exportable data.
 */
// export const createExportData = (divisionData, settings) => {
//   const safeNumber = (val) => {
//     if (typeof val === "number") return val;
//     if (typeof val === "string") return parseFloat(val) || 0;
//     return 0;
//   };
//   const safeString = (val) => (typeof val === "string" ? val : "");

//   const calculateDivisionTotal = (division) => {
//     if (!division?.subdivisions) return 0;
//     return Object.values(division.subdivisions).reduce((acc, sub) => {
//       return acc + safeNumber(sub["Total Cost"]);
//     }, 0);
//   };

//   const calculateGrandTotal = () => {
//     if (!divisionData.divisionOrder) return 0;
//     return divisionData.divisionOrder.reduce((acc, divKey) => {
//       return acc + calculateDivisionTotal(divisionData[divKey]);
//     }, 0);
//   };

//   const exportDivisions =
//     divisionData.divisionOrder?.map((divKey) => {
//       const division = divisionData[divKey];
//       const columns = settings[divKey].columns;
//       return {
//         divisionKey: divKey,
//         divisionName: division?.name ?? "",
//         total: calculateDivisionTotal(division),
//         columns,
//         subdivisions:
//           division?.subdivisionOrder?.map((subKey) => {
//             const sub = division.subdivisions[subKey] ?? {};
//             return {
//               name: sub?.name ?? "",
//               quantity: columns.quantities ? sub?.quantity ?? "" : null,
//               units: columns.units ? sub?.["Units"] ?? "" : null,
//               unitCost: columns.unitCosts ? sub?.["unit cost"] ?? 0 : null,
//               totalCost: columns.totalCosts ? sub?.["Total Cost"] ?? 0 : null,
//               wasteFactor: columns.wasteFactor
//                 ? sub?.["Waste Factor"] ?? ""
//                 : null,
//             };
//           }) ?? [],
//       };
//     }) ?? [];

//   return {
//     divisions: exportDivisions,
//     grandTotal: calculateGrandTotal(),
//   };
// };

export const createExportData = (divisionData, settings) => {
  // Helper function to safely get numeric values
  const safeNumber = (val) => {
    if (typeof val === "number") return val;
    if (typeof val === "string") return parseFloat(val) || 0;
    return 0;
  };

  // Helper function to find property value regardless of case
  const getPropertyCaseInsensitive = (obj, targetKey) => {
    if (!obj) return null;

    // Try direct access first
    if (obj[targetKey] !== undefined) return obj[targetKey];

    // Convert target key to lowercase for comparison
    const targetKeyLower = targetKey.toLowerCase().replace(/\s+/g, "");

    // Find the first matching key
    const matchingKey = Object.keys(obj).find(
      (key) => key.toLowerCase().replace(/\s+/g, "") === targetKeyLower
    );

    return matchingKey !== undefined ? obj[matchingKey] : null;
  };

  const calculateDivisionTotal = (division) => {
    if (!division?.subdivisions) return 0;
    return Object.values(division.subdivisions).reduce((acc, sub) => {
      // Look for total cost with any case variation
      const totalCost =
        getPropertyCaseInsensitive(sub, "totalcost") ||
        getPropertyCaseInsensitive(sub, "total cost") ||
        getPropertyCaseInsensitive(sub, "Total Cost");
      return acc + safeNumber(totalCost);
    }, 0);
  };

  const calculateGrandTotal = () => {
    if (!divisionData.divisionOrder) return 0;
    return divisionData.divisionOrder.reduce((acc, divKey) => {
      return acc + calculateDivisionTotal(divisionData[divKey]);
    }, 0);
  };

  const exportDivisions =
    divisionData.divisionOrder?.map((divKey) => {
      const division = divisionData[divKey];
      const columns = settings[divKey].columns;

      return {
        divisionKey: divKey,
        divisionName: getPropertyCaseInsensitive(division, "name") ?? "",
        total: calculateDivisionTotal(division),
        columns,
        subdivisions:
          division?.subdivisionOrder?.map((subKey) => {
            const sub = division.subdivisions[subKey] ?? {};

            // Handle various property names
            const name =
              getPropertyCaseInsensitive(sub, "name") ??
              getPropertyCaseInsensitive(sub, "Name") ??
              "";

            const quantity =
              getPropertyCaseInsensitive(sub, "quantity") ??
              getPropertyCaseInsensitive(sub, "Quantity") ??
              "";

            const units =
              getPropertyCaseInsensitive(sub, "units") ??
              getPropertyCaseInsensitive(sub, "Units") ??
              "";

            const unitCost =
              getPropertyCaseInsensitive(sub, "unitcost") ??
              getPropertyCaseInsensitive(sub, "unit cost") ??
              getPropertyCaseInsensitive(sub, "Unit Cost");

            const totalCost =
              getPropertyCaseInsensitive(sub, "totalcost") ??
              getPropertyCaseInsensitive(sub, "total cost") ??
              getPropertyCaseInsensitive(sub, "Total Cost");

            const wasteFactor =
              getPropertyCaseInsensitive(sub, "wastefactor") ??
              getPropertyCaseInsensitive(sub, "waste factor") ??
              getPropertyCaseInsensitive(sub, "Waste Factor") ??
              "";

            return {
              name,
              quantity: columns.quantities ? quantity : null,
              units: columns.units ? units : null,
              unitCost: columns.unitCosts ? safeNumber(unitCost) : null,
              totalCost: columns.totalCosts ? safeNumber(totalCost) : null,
              wasteFactor: columns.wasteFactor ? wasteFactor : null,
            };
          }) ?? [],
      };
    }) ?? [];

  const result = {
    divisions: exportDivisions,
    grandTotal: calculateGrandTotal(),
  };

  return result;
};
