// src/components/Jobs/JobCreationPage.jsx
import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Paper, Grid } from '@mui/material';
import MainLayout from '../MainLayout';

function JobCreationPage() {
  const [jobName, setJobName] = useState('');
  const [jobDescription, setJobDescription] = useState('');
  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [stateValue, setStateValue] = useState('');
  const [zip, setZip] = useState('');
  const [clientName, setClientName] = useState('');
  const [clientEmail, setClientEmail] = useState('');

  const handleCreateJob = async () => {
    // Implement job creation logic here.


  };

  return (
    <MainLayout>
      <Box sx={{ p: 2, ml: 35 }}>
        <Typography variant="h5" gutterBottom>
          Create a New Job
        </Typography>
        <Paper sx={{ p: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Job Name"
                variant="outlined"
                fullWidth
                value={jobName}
                onChange={(e) => setJobName(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Job Description"
                variant="outlined"
                multiline
                rows={4}
                fullWidth
                value={jobDescription}
                onChange={(e) => setJobDescription(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Street Address"
                variant="outlined"
                fullWidth
                value={street}
                onChange={(e) => setStreet(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="City"
                variant="outlined"
                fullWidth
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={1}>
              <TextField
                label="State"
                variant="outlined"
                fullWidth
                value={stateValue}
                onChange={(e) => setStateValue(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                label="Zip Code"
                variant="outlined"
                fullWidth
                value={zip}
                onChange={(e) => setZip(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Client Name"
                variant="outlined"
                fullWidth
                value={clientName}
                onChange={(e) => setClientName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Client Email"
                variant="outlined"
                fullWidth
                type="email"
                value={clientEmail}
                onChange={(e) => setClientEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Button variant="contained" color="primary" onClick={handleCreateJob}>
                Create Job
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </MainLayout>
  );
}

export default JobCreationPage;
