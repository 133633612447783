import React, { useState } from "react";
import {
  Paper,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Box,
  FormControlLabel,
  Radio,
  Alert,
  Snackbar,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  generateSignedURLsForFiles,
  uploadFiles,
  processCostFilesToExtractData,
} from "../../services/pricingService";

function CustomPricingSection({ selectedOption, handleOptionChange, onNext }) {
  const [files, setFiles] = useState([]);
  const [uploadedPaths, setUploadedPaths] = useState([]);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false); // Snackbar state
  const [isProcessing, setIsProcessing] = useState(false);

  const MAX_FILES = 15;
  const MAX_FILE_SIZE_MB = 20;
  const ALLOWED_TYPES = [
    "application/pdf",
    "image/jpeg",
    "image/png",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "text/csv",
    "text/tab-separated-values",
  ];

  const handleOpenDialog = () => setOpen(true);

  const handleCloseDialog = () => {
    setError("");
    setOpen(false);
  };

  const handleFileSelection = (event) => {
    const selectedFiles = Array.from(event.target.files);
    let newFiles = [];
    let errorMessage = "";

    selectedFiles.forEach((file) => {
      if (!ALLOWED_TYPES.includes(file.type)) {
        errorMessage = "Unsupported file type detected.";
      } else if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
        errorMessage = "One or more files exceed the 20 MB size limit.";
      } else {
        newFiles.push(file);
      }
    });

    if (files.length + newFiles.length > MAX_FILES) {
      errorMessage = `You can only upload up to ${MAX_FILES} files.`;
    }

    if (errorMessage) {
      setError(errorMessage);
      setSnackbarOpen(true)
    } else {
      setError("");
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleUploadAll = async () => {
    try {
      setIsProcessing(true);

      //Step 1: Generate SignedURLS for each of the uploaded file
      const signedUrls = await generateSignedURLsForFiles(files);
      console.log(signedUrls);
      if (signedUrls.error) {
        console.error(signedUrls.error);
        throw new Error(signedUrls.error);
      }  
      //Step 2: Upload each file to its respective signed url 
      const paths = await uploadFiles(signedUrls);
      if (paths.error) {
        throw new Error(paths.error);
      }  
      console.log(`Paths = ${paths}`);
      setUploadedPaths(paths);
      // Step 3: Process each file to extract data
      const processFilesResult = await processCostFilesToExtractData(paths);
      if (processFilesResult.error) {
        throw new Error(processFilesResult.error);
      }  
      alert("All files uploaded and processed successfully!");
      setOpen(false);
    } catch (error) {
      console.error("Error during file upload:", error.message);
      setError(error.message);
      setSnackbarOpen(true);
    } finally {
      setIsProcessing(false);
      setFiles([]);
    }  
  };

  return (
    <Paper
      variant="outlined"
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "1rem",
        borderColor: selectedOption === "upload" ? "primary.main" : "grey.300",
        // opacity: selectedOption === "upload" || !selectedOption ? 1 : 0.5, // Dim if not selected
        // pointerEvents: selectedOption === "upload" || !selectedOption ? "auto" : "none", // Disable interaction

      }}
    >
      {/* Radio Button */}
      <FormControlLabel
        value="upload"
        control={
          <Radio
            checked={selectedOption === "upload"}
            onChange={handleOptionChange}
          />
        }
        label={<Typography variant="h6">Custom Pricing</Typography>}
        sx={{ marginBottom: "1rem" }}
      />

      {uploadedPaths.length > 0 && !error && (
        <Typography variant="body2" sx={{ marginBottom: "1rem" }}>
          {uploadedPaths.length} files uploaded successfully.
        </Typography>
      )}

      <Box>
        <Typography variant="body2" sx={{ marginBottom: "1rem" }}>
          Supported file types: PDFs, Images, Excel, CSV/TSV | Max 15 files | 20 MB each
        </Typography>
        <Button
          variant="contained"
          onClick={handleOpenDialog}
          disabled={selectedOption !== "upload"}
        >
          Select Files
        </Button>
      </Box>

      <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth="sm">
        <DialogTitle>Select Files</DialogTitle>
        <DialogContent sx={{ maxHeight: "300px", overflowY: "auto" }}>
          {error && (
            <Typography color="error" sx={{ marginBottom: "1rem" }}>
              {error}
            </Typography>
          )}

          <Button variant="contained" component="label">
            Add Files
            <input
              type="file"
              hidden
              multiple
              onChange={handleFileSelection}
              accept=".pdf, .jpeg, .jpg, .png, .xlsx, .csv, .tsv"
            />
          </Button>

          {files.length > 0 && (
            <Box sx={{ marginTop: "1rem" }}>
              <Typography variant="subtitle1">Selected Files:</Typography>
              <List>
                {files.map((file, index) => (
                  <ListItem
                    key={index}
                    secondaryAction={
                      <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveFile(index)}>
                        <DeleteIcon />
                      </IconButton>
                    }
                  >
                    <ListItemText
                      primary={file.name}
                      secondary={`Size: ${(file.size / (1024 * 1024)).toFixed(2)} MB`}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary" disabled={isProcessing}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUploadAll}
            disabled={files.length === 0 || isProcessing}
          >
            Upload All Files
          </Button>
        </DialogActions>
      </Dialog>
      {/* Snackbar for Error Messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>
    </Paper>
  );
}

export default CustomPricingSection;
