//EditorRegistry.js
import CompanyOverviewEditor from "./FormComponents/CompanyOverviewEditor";
import LicenseEditor from "./FormComponents/LicenseEditor";
import CompanyOverviewPDF from "./PdfComponents/CompanyOverviewPdf";
import LicenseInsurancePDF from "./PdfComponents/LicensePdf";
import ConstructionDataViewer from "./FormComponents/CostBreakDownEditor";
import CostBreakDownPDF from "./PdfComponents/CostBreakDownPdf";
import { useSelector } from "react-redux";

const companyData = {
  foundedYear: "2020",
  foundingStory: "Your company founding story and journey...",
  missionStatement: "Your company mission statement",
  coreValues: ["Value 1", "Value 2", "Value 3"],
  milestones: [
    { year: "2020", achievement: "Achievement 1" },
    { year: "2021", achievement: "Achievement 2" },
    { year: "2022", achievement: "Achievement 3" },
  ],
  serviceAreas: ["Area 1", "Area 2", "Area 3", "Area 4"],
};

const licenseInsuranceData = {
  licenseNumber: "123456",
  validUntil: "12/31/2024",
  classifications: "Type A, Type B, Type C",
  liability: "1,000,000",
  workersComp: "Full coverage for all employees",
  bondCapacity: "500,000",
};

// Create a function that returns the registry with current data
const createRegistry = (proposalData = {}) => ({
  company: {
    overview: {
      data: companyData,
      component: CompanyOverviewEditor,
      title: "Company Overview Editor",
      pdfContent: function PDFPreviewWrapper(companyData) {
        return <CompanyOverviewPDF {...companyData} />;
      },
    },
    license: {
      data: licenseInsuranceData,
      component: LicenseEditor,
      title: "License & Insurance Editor",
      pdfContent: function PDFPreviewWrapper(licenseInsuranceData) {
        return <LicenseInsurancePDF {...licenseInsuranceData} />;
      },
    },
  },
  financial: {
    costs: {
      data: proposalData, // Now this will use the current proposal data
      component: ConstructionDataViewer,
      title: "Cost Break Down",
      pdfContent: function PDFPreviewWrapper(costData) {
        return <CostBreakDownPDF data={costData} />;
      },
    },
  },
});

// Helper function to get editor component
export const getEditor = (sectionId, itemId, proposalData) => {
  // Create a new registry with the current proposal data
  const currentRegistry = createRegistry(proposalData);
  return currentRegistry[sectionId]?.[itemId] || null;
};

// Export a default registry for initial state
export const EDITOR_REGISTRY = createRegistry();
