import React from 'react';
import {
  Card,
  CardHeader,
  IconButton,
  Divider,
  CardContent,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Typography
} from '@mui/material';
import {
  Delete as DeleteIcon,
  DragHandle as DragHandleIcon,
  Add as AddIcon,
  ExpandLess,
  ExpandMore
} from '@mui/icons-material';
import SubdivisionRow from './SubdivisionRow';

const DivisionCard = React.memo(function DivisionCard({
  divisionKey,
  division,
  editMode,
  isExpanded,
  handleDivisionDragStart,
  handleDivisionDrop,
  handleDeleteDivision,
  handleDivisionChange,
  handleDivisionExpandToggle,
  handleAddSubdivision,
  handleSubdivisionChange,
  handleDeleteSubdivision,
  handleSubdivisionDragStart,
  handleSubdivisionDrop,
  handleSubdivisionImagesClick,
}) {
  return (
    <Card
      key={divisionKey}
      draggable={editMode}
      onDragStart={(e) => { e.stopPropagation(); handleDivisionDragStart(divisionKey); }}
      onDragOver={(e) => e.preventDefault()}
      onDrop={(e) => { e.preventDefault(); handleDivisionDrop(divisionKey); }}
      sx={{ mb: 2, cursor: editMode ? 'grab' : 'default' }}
    >
      <CardHeader
        avatar={
          editMode && (
            <IconButton
              sx={{ cursor: 'grab' }}
              onDragStart={(e) => e.stopPropagation()}
              aria-label="Drag Division"
            >
              <DragHandleIcon />
            </IconButton>
          )
        }
        action={
          editMode && (
            <IconButton onClick={() => handleDeleteDivision(divisionKey)} aria-label="Delete Division">
              <DeleteIcon />
            </IconButton>
          )
        }
        title={
          editMode ? (
            <Typography variant="h6">
              <input
                style={{border:'1px solid #ccc', padding:'4px', borderRadius:'4px'}}
                value={division.name}
                onChange={(e) => handleDivisionChange(divisionKey, 'name', e.target.value)}
              />
            </Typography>
          ) : (
            <Typography
              variant="h6"
              sx={{ cursor: 'pointer' }}
              onClick={() => handleDivisionExpandToggle(divisionKey)}
            >
              {division.name}
            </Typography>
          )
        }
        subheader={
          <IconButton onClick={() => handleDivisionExpandToggle(divisionKey)} aria-label="Toggle Expansion">
            {isExpanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        }
      />
      {isExpanded && (
        <>
          <Divider />
          <CardContent>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    {editMode && <TableCell>Move</TableCell>}
                    <TableCell>Subdivision Name</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Units</TableCell>
                    <TableCell>Unit Cost</TableCell>
                    <TableCell>Total Cost</TableCell>
                    <TableCell>Waste Factor</TableCell>
                    {editMode && <TableCell>Actions</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(division.subdivisions || {}).map((subKey) => {
                    const subdivision = division.subdivisions[subKey];
                    return (
                      <SubdivisionRow
                        key={subKey}
                        editMode={editMode}
                        subdivision={subdivision}
                        subKey={subKey}
                        divisionKey={divisionKey}
                        handleSubdivisionChange={handleSubdivisionChange}
                        handleDeleteSubdivision={handleDeleteSubdivision}
                        onDragStart={(e) => { e.stopPropagation(); handleSubdivisionDragStart(divisionKey, subKey); }}
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={(e) => { e.preventDefault(); handleSubdivisionDrop(divisionKey, subKey); }}
                        onImagesClick={handleSubdivisionImagesClick}
                      />
                    );
                  })}
                  {editMode && (
                    <TableRow>
                      <TableCell colSpan={8}>
                        <Button startIcon={<AddIcon />} onClick={() => handleAddSubdivision(divisionKey)}>
                          Add Subdivision
                        </Button>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </>
      )}
    </Card>
  );
});

export default DivisionCard;
