import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useContext,
} from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  TextField,
  IconButton,
  Typography,
  Toolbar,
  Button,
  CircularProgress,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  Divider,
} from "@mui/material";
import { Link } from "react-router-dom";
import {
  Edit as EditIcon,
  Save as SaveIcon,
  Add as AddIcon,
  Search as SearchIcon,
  Home as HomeIcon,
  SquareFoot as SquareFootIcon,
  Bed as BedIcon,
  Bathroom as BathroomIcon,
} from "@mui/icons-material";
import NoteAddIcon from "@mui/icons-material/NoteAdd";

import MainLayout from "../MainLayout";
import {
  createJob,
  getTakeoffData,
  getSnapshotData,
  getChatMessages,
  sendChatMessage,
  updateTakeoffData,
} from "../../services/jobService";
import { AuthContext } from "../../context/authContext";
import SnapshotMiniPreview from "./SnapshotMiniPreview";
import ChatPreview from "./ChatPreview";
import SnapshotDrawer from "./SnapshotDrawer";
import ChatDrawer from "./ChatDrawer";
import ImagesDrawer from "./ImagesDrawer";
import DivisionCard from "./DivisionCard";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

import { createProposal } from "../../services/proposalService";
import { useDispatch, useSelector } from "react-redux";
import { setproposalId, updateProposalData } from "../../store";

function ValidationOutput() {
  const dispatch = useDispatch();
  const { jobId } = useParams();

  const [loadingSnapshot, setLoadingSnapshot] = useState(true);
  const [snapshotData, setSnapshotData] = useState(null);
  const [initialVisit, setInitialVisit] = useState(true);
  const [loadingTakeoff, setLoadingTakeoff] = useState(false);
  const [tableData, setTableData] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [expandedDivisions, setExpandedDivisions] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [acceptedSnapshot, setAcceptedSnapshot] = useState(false);
  const [snapshotOpen, setSnapshotOpen] = useState(false);

  const [chatOpen, setChatOpen] = useState(false);

  const [proposalName, setProposalName] = useState("");
  const [proposalDescription, setProposalDescription] = useState("");

  const [draggedDivision, setDraggedDivision] = useState(null);
  const [draggedSubdivision, setDraggedSubdivision] = useState({
    divisionKey: null,
    subKey: null,
  });

  const [imageDrawerOpen, setImageDrawerOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);

  const [proposalDialogOpen, setProposalDialogOpen] = useState(false);
  const navigate = useNavigate();
  const { isAuthenticated } = useContext(AuthContext);

  const handleSubdivisionImagesClick = useCallback((images) => {
    setSelectedImages(images);
    setImageDrawerOpen(true);
  }, []);

  const handleImageDrawerClose = useCallback(() => {
    setImageDrawerOpen(false);
    setSelectedImages([]);
  }, []);

  const handleDialogNext = async () => {
    // call the createProposal function
    const newProposal = await createProposal(
      jobId,
      proposalName,
      proposalDescription,
      tableData
    );
    if (newProposal && newProposal.id) {
      // If successful, navigate to the proposal editor with the new proposal ID
      dispatch(setproposalId(newProposal.id));
      dispatch(
        updateProposalData({ ...newProposal.proposal_data, settings: {} })
      );
      navigate(`/proposal_editor`);
    } else {
      console.error("Failed to create proposal");
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      const fetchSnapshot = async () => {
        if (!jobId) return;
        const data = await getSnapshotData(jobId);
        setSnapshotData(data);
        setLoadingSnapshot(false);
      };
      fetchSnapshot();
    } else {
      navigate("/login");
    }
  }, [jobId, isAuthenticated, navigate]);

  useEffect(() => {
    setAcceptedSnapshot(false);
    setInitialVisit(true);
    setTableData({});
    setLoadingTakeoff(true);
    getTakeoffData(jobId).then((data) => {
      setLoadingTakeoff(false);
      if (data && !data.error) {
        if (!data.divisionOrder) {
          data.divisionOrder = Object.keys(data).filter(
            (k) => k !== "divisionOrder"
          );
        }
        data.divisionOrder.forEach((dKey) => {
          if (!data[dKey].subdivisionOrder) {
            data[dKey].subdivisionOrder = Object.keys(
              data[dKey].subdivisions || {}
            );
          }
        });
        setTableData(data);
      }
    });
  }, [jobId]);

  useEffect(() => {
    async function tryLoadTakeoff() {
      if (loadingSnapshot || !snapshotData) return;
      setLoadingTakeoff(true);
      const result = await getTakeoffData(jobId);
      setLoadingTakeoff(false);

      if (result && !result.error && Object.keys(result).length > 0) {
        if (!result.divisionOrder) {
          result.divisionOrder = Object.keys(result).filter(
            (k) => k !== "divisionOrder"
          );
        }
        result.divisionOrder.forEach((dKey) => {
          if (!result[dKey].subdivisionOrder) {
            result[dKey].subdivisionOrder = Object.keys(
              result[dKey].subdivisions || {}
            );
          }
        });
        setTableData(result);
        setInitialVisit(false);
        setAcceptedSnapshot(true);
      } else {
        setInitialVisit(true);
      }
    }
    if (!acceptedSnapshot) {
      tryLoadTakeoff();
    }
  }, [jobId, snapshotData, loadingSnapshot, acceptedSnapshot]);

  useEffect(() => {
    async function fetchTakeoff() {
      if (!acceptedSnapshot || !initialVisit) return;
      setLoadingTakeoff(true);
      const result = await getTakeoffData(jobId);
      setLoadingTakeoff(false);
      if (result && !result.error) {
        if (!result.divisionOrder) {
          result.divisionOrder = Object.keys(result).filter(
            (k) => k !== "divisionOrder"
          );
        }
        result.divisionOrder.forEach((dKey) => {
          if (!result[dKey].subdivisionOrder) {
            result[dKey].subdivisionOrder = Object.keys(
              result[dKey].subdivisions || {}
            );
          }
        });
        setTableData(result);
      } else {
        console.error("Failed to load takeoff data:", result?.error);
      }
    }
    fetchTakeoff();
  }, [acceptedSnapshot, initialVisit, jobId]);

  const handleEditToggle = useCallback(() => {
    if (editMode && jobId) {

      updateTakeoffData(jobId, tableData).then((res) => {
        if (res && !res.error) {
          console.log('Takeoff data saved successfully.');
          getTakeoffData(jobId).then(newData => {
            if (newData && !newData.error) {
              setTableData(newData);
            }
          });
        } else {
          alert(res?.error || 'Failed to save. Please check your inputs.');
          console.error('Failed to save takeoff data:', res?.error);
        }
      }).catch((err) => {
        console.error('Error saving takeoff data:', err);
      });
    }
    setEditMode((prev) => !prev);
  }, [editMode, jobId, tableData]);

  const handleDivisionExpandToggle = useCallback((divisionKey) => {
    setExpandedDivisions((prev) => ({
      ...prev,
      [divisionKey]: !prev[divisionKey],
    }));
  }, []);

  const handleAddSubdivision = useCallback((divisionKey) => {
    const newSubdivisionKey = `Subdivision_${Date.now()}`;
    setTableData((prevData) => {
      const updatedData = { ...prevData };
      updatedData[divisionKey].subdivisions[newSubdivisionKey] = {
        name: "",
        quantity: "",
        Units: "",
        "Waste Factor": "",
        images: [],
      };
      updatedData[divisionKey].subdivisionOrder = [
        ...updatedData[divisionKey].subdivisionOrder,
        newSubdivisionKey,
      ];
      return updatedData;
    });
  }, []);

  const handleDeleteSubdivision = useCallback((divisionKey, subdivisionKey) => {
    setTableData((prevData) => {
      const updatedData = { ...prevData };
      delete updatedData[divisionKey].subdivisions[subdivisionKey];
      updatedData[divisionKey].subdivisionOrder = updatedData[
        divisionKey
      ].subdivisionOrder.filter((key) => key !== subdivisionKey);
      return updatedData;
    });
  }, []);

  const handleSubdivisionChange = useCallback(
    (divisionKey, subdivisionKey, field, value) => {
      setTableData((prevData) => {
        const updatedData = { ...prevData };
        updatedData[divisionKey].subdivisions[subdivisionKey][field] = value;
        return updatedData;
      });
    },
    []
  );

  const handleDivisionChange = useCallback((divisionKey, field, value) => {
    setTableData((prevData) => {
      const updatedData = { ...prevData };
      updatedData[divisionKey][field] = value;
      return updatedData;
    });
  }, []);

  const handleAddDivision = useCallback(() => {
    const newDivisionKey = `Division_${Date.now()}`;
    setTableData((prevData) => {
      const updatedData = { ...prevData };
      updatedData[newDivisionKey] = {
        name: "",
        images: [],
        subdivisions: {},
        subdivisionOrder: [],
      };
      if (!updatedData.divisionOrder) {
        updatedData.divisionOrder = [];
      }
      updatedData.divisionOrder = [
        ...updatedData.divisionOrder,
        newDivisionKey,
      ];
      return updatedData;
    });
  }, []);

  const handleDeleteDivision = useCallback((divisionKey) => {
    setTableData((prevData) => {
      const updatedData = { ...prevData };
      delete updatedData[divisionKey];
      updatedData.divisionOrder = updatedData.divisionOrder.filter(
        (dKey) => dKey !== divisionKey
      );
      return updatedData;
    });

    setExpandedDivisions((prev) => {
      const newExpanded = { ...prev };
      delete newExpanded[divisionKey];
      return newExpanded;
    });
  }, []);

  const handleCreateProposal = useCallback(() => {
    setProposalDialogOpen(true);
  }, []);

  const handleDialogClose = useCallback(() => {
    setProposalDialogOpen(false);
  }, []);

  const filteredDivisions = useMemo(() => {
    const query = searchQuery.toLowerCase();
    if (!tableData.divisionOrder) return [];
    return tableData.divisionOrder.filter((divisionKey) => {
      const division = tableData[divisionKey];
      if (!division) return false;
      const divisionMatch = division.name.toLowerCase().includes(query);
      const subdivisionMatch = division.subdivisionOrder.some((subKey) =>
        division.subdivisions[subKey].name.toLowerCase().includes(query)
      );
      return divisionMatch || subdivisionMatch;
    });
  }, [tableData, searchQuery]);

  const handleSnapshotOpen = useCallback(() => {
    setSnapshotOpen(true);
    setChatOpen(false);
  }, []);

  const handleSnapshotClose = useCallback(() => {
    setSnapshotOpen(false);
  }, []);

  const handleChatToggle = useCallback(() => {
    setChatOpen((prev) => {
      if (!prev) setSnapshotOpen(false);
      return !prev;
    });
  }, []);

  // Updated handleChatSend to accept a message argument
  const handleChatSend = useCallback(
    async (message) => {
      if (!message.trim() || !jobId) return [];
      try {
        const newMessages = await sendChatMessage(jobId, message);
        return newMessages;
      } catch (error) {
        console.error("Error sending chat message:", error);
        return [];
      }
    },
    [jobId]
  );

  const handleDivisionDragStart = useCallback((divisionKey) => {
    setDraggedDivision(divisionKey);
  }, []);

  const handleDivisionDrop = useCallback(
    (targetDivisionKey) => {
      if (draggedDivision === null || draggedDivision === targetDivisionKey)
        return;

      const divisionKeys = filteredDivisions;
      const draggedIndex = divisionKeys.indexOf(draggedDivision);
      const targetIndex = divisionKeys.indexOf(targetDivisionKey);

      if (draggedIndex === -1 || targetIndex === -1) return;

      setTableData((prevData) => {
        const updatedData = { ...prevData };
        const reorderedKeys = Array.from(updatedData.divisionOrder);
        reorderedKeys.splice(draggedIndex, 1);
        reorderedKeys.splice(targetIndex, 0, draggedDivision);
        updatedData.divisionOrder = reorderedKeys;
        return updatedData;
      });

      setDraggedDivision(null);
    },
    [draggedDivision, filteredDivisions]
  );

  const handleSubdivisionDragStart = useCallback((divisionKey, subKey) => {
    setDraggedSubdivision({ divisionKey, subKey });
  }, []);

  const handleSubdivisionDrop = useCallback(
    (targetDivisionKey, targetSubKey) => {
      const { divisionKey: sourceDivisionKey, subKey: sourceSubKey } =
        draggedSubdivision;

      if (sourceDivisionKey !== targetDivisionKey) return;

      setTableData((prevData) => {
        const updatedData = { ...prevData };
        const sourceSubdivisions =
          updatedData[sourceDivisionKey].subdivisionOrder;
        const sourceIndex = sourceSubdivisions.indexOf(sourceSubKey);
        const targetIndex = sourceSubdivisions.indexOf(targetSubKey);

        if (
          sourceIndex === -1 ||
          targetIndex === -1 ||
          sourceSubKey === targetSubKey
        )
          return updatedData;

        const reorderedSubKeys = Array.from(sourceSubdivisions);
        reorderedSubKeys.splice(sourceIndex, 1);
        reorderedSubKeys.splice(targetIndex, 0, sourceSubKey);

        updatedData[sourceDivisionKey].subdivisionOrder = reorderedSubKeys;
        return updatedData;
      });

      setDraggedSubdivision({ divisionKey: null, subKey: null });
    },
    [draggedSubdivision]
  );

  const renderSnapshotCard = () => {
    return (
      <Card variant="outlined" sx={{ borderRadius: 2 }}>
        <CardHeader
          avatar={<HomeIcon color="primary" />}
          title="Home Summary"
          titleTypographyProps={{ variant: "h6", fontWeight: "bold" }}
        />
        <Divider />
        <CardContent>
          <Grid container spacing={2} alignItems="flex-start">
            <Grid item xs={12} display="flex" alignItems="center" gap={1}>
              <HomeIcon color="action" />
              <Typography variant="body1">
                <strong>Project Name:</strong>{" "}
                {snapshotData?.project_name || "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={12} display="flex" alignItems="center" gap={1}>
              <HomeIcon color="action" />
              <Typography variant="body1">
                <strong>Location:</strong> {snapshotData?.location || "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={12} display="flex" alignItems="center" gap={1}>
              <HomeIcon color="action" />
              <Typography variant="body1">
                <strong>Owner:</strong> {snapshotData?.owner || "N/A"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              display="flex"
              alignItems="center"
              gap={1}
            >
              <SquareFootIcon color="action" />
              <Typography variant="body1">
                <strong>Area:</strong> {snapshotData?.area_square_feet || "N/A"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              display="flex"
              alignItems="center"
              gap={1}
            >
              <BedIcon color="action" />
              <Typography variant="body1">
                <strong>Bedrooms:</strong> {snapshotData?.bedrooms || "N/A"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              display="flex"
              alignItems="center"
              gap={1}
            >
              <BathroomIcon color="action" />
              <Typography variant="body1">
                <strong>Bathrooms:</strong> {snapshotData?.bathrooms || "N/A"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              display="flex"
              alignItems="center"
              gap={1}
            >
              <HomeIcon color="action" />
              <Typography variant="body1">
                <strong>Home Type:</strong> {snapshotData?.hometype || "N/A"}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ justifyContent: "flex-end" }}>
          {initialVisit && !acceptedSnapshot && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setAcceptedSnapshot(true)}
            >
              Accept Snapshot
            </Button>
          )}
        </CardActions>
      </Card>
    );
  };

  const renderTakeoffData = () => {
    if (loadingTakeoff) {
      return (
        <Box sx={{ textAlign: "center", mt: 4 }}>
          <Typography variant="h5" gutterBottom>
            Loading takeoff data...
          </Typography>
          <CircularProgress />
        </Box>
      );
    }

    if (
      !tableData ||
      !tableData.divisionOrder ||
      tableData.divisionOrder.length === 0
    ) {
      return (
        <Typography variant="h6" sx={{ mt: 4 }}>
          No takeoff data available. Please try again.
        </Typography>
      );
    }

    return (
      <>
        <Toolbar disableGutters sx={{ p: 0, mb: 2 }}>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: <SearchIcon sx={{ mr: 1 }} />,
            }}
            sx={{ mr: 2 }}
          />
          <Button
            variant="contained"
            startIcon={editMode ? <SaveIcon /> : <EditIcon />}
            onClick={handleEditToggle}
            sx={{ mr: 2 }}
          >
            {editMode ? "Save" : "Edit"}
          </Button>
          {editMode && (
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleAddDivision}
            >
              Add Division
            </Button>
          )}
        </Toolbar>

        <div>
          {filteredDivisions.map((divisionKey) => {
            const division = tableData[divisionKey];
            const isExpanded = expandedDivisions[divisionKey] || false;

            return (
              <DivisionCard
                key={divisionKey}
                divisionKey={divisionKey}
                division={division}
                editMode={editMode}
                isExpanded={isExpanded}
                handleDivisionDragStart={handleDivisionDragStart}
                handleDivisionDrop={handleDivisionDrop}
                handleDeleteDivision={handleDeleteDivision}
                handleDivisionChange={handleDivisionChange}
                handleDivisionExpandToggle={handleDivisionExpandToggle}
                handleAddSubdivision={handleAddSubdivision}
                handleSubdivisionChange={handleSubdivisionChange}
                handleDeleteSubdivision={handleDeleteSubdivision}
                handleSubdivisionDragStart={handleSubdivisionDragStart}
                handleSubdivisionDrop={handleSubdivisionDrop}
                handleSubdivisionImagesClick={handleSubdivisionImagesClick}
              />
            );
          })}
        </div>
      </>
    );
  };

  return (
    <MainLayout>
      <Box
        sx={{
          padding: 4,
          display: "flex",
          flexDirection: "row",
          gap: 1,
          height: "100vh",
          ml: 30,
        }}
      >
        <Box sx={{ flex: 1, minWidth: 0, overflowY: "auto" }}>
          <Typography variant="h4" gutterBottom>
            Validation Output
          </Typography>

          {!acceptedSnapshot && initialVisit && snapshotData && (
            <Box sx={{ mt: 4, maxWidth: "600px" }}>
              <Typography variant="h5" gutterBottom>
                Snapshot Overview
              </Typography>
              {renderSnapshotCard()}
            </Box>
          )}

          {(acceptedSnapshot || !initialVisit) && renderTakeoffData()}
        </Box>

        {(acceptedSnapshot || !initialVisit) && snapshotData && (
          <Box
            sx={{
              width: "300px",
              flexShrink: 0,
              display: "flex",
              flexDirection: "column",
              gap: 0.5,
              height: "100%",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <SnapshotMiniPreview
                onOpen={handleSnapshotOpen}
                snapshotData={snapshotData}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <ChatPreview onOpen={handleChatToggle} />
            </Box>
            <Button
              variant="contained"
              startIcon={<NoteAddIcon />}
              onClick={handleCreateProposal}
              sx={{
                margin: "30px 24px",
                padding: "12px 24px", // Larger padding for the button
                fontSize: "1rem", // Slightly larger font size
                borderRadius: 8, // Rounded corners
                backgroundColor: "#1976d2", // Use a vibrant blue color
                color: "#fff", // White text
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)", // Add shadow for a floating effect
                textTransform: "uppercase", // Use uppercase letters for the label
                transition: "transform 0.2s ease, box-shadow 0.2s ease", // Add smooth hover effect
                "&:hover": {
                  backgroundColor: "#115293", // Darker blue on hover
                  transform: "scale(1.05)", // Slightly enlarge on hover
                  boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.3)",
                },
              }}
            >
              Create Proposal
            </Button>
          </Box>
        )}
      </Box>

      <SnapshotDrawer
        open={snapshotOpen}
        onClose={handleSnapshotClose}
        snapshotData={snapshotData}
        initialVisit={initialVisit}
        acceptedSnapshot={acceptedSnapshot}
        setAcceptedSnapshot={setAcceptedSnapshot}
      />

      <ChatDrawer
        open={chatOpen}
        onClose={handleChatToggle}
        onChatSend={handleChatSend}
        jobId={jobId}
      />

      <ImagesDrawer
        open={imageDrawerOpen}
        onClose={handleImageDrawerClose}
        jobId={jobId}
        imageUrls={selectedImages}
      />
      <Dialog
        open={proposalDialogOpen}
        onClose={handleDialogClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Create Proposal</DialogTitle>
        <DialogContent>
          <Box component="form" noValidate autoComplete="off">
            <TextField
              fullWidth
              margin="normal"
              label="Proposal Name"
              variant="outlined"
              value={proposalName}
              onChange={(e) => setProposalName(e.target.value)}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Proposal Description"
              variant="outlined"
              multiline
              rows={4}
              value={proposalDescription}
              onChange={(e) => setProposalDescription(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDialogNext}
          >
            Next
          </Button>
        </DialogActions>
      </Dialog>
    </MainLayout>
  );
}

export default ValidationOutput;
