// src/components/Profile/ManageUsers.js

import React, { useState, useContext, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  CircularProgress,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Snackbar,
  Alert,
  Divider
} from '@mui/material';
import MainLayout from '../MainLayout';
import { AuthContext } from '../../context/authContext';
import { useNavigate } from 'react-router-dom';
import { fetchUsers, getIsAdmin, updateUserRole } from '../../services/userService';
import { permissionsDescriptions, rolePermissionsDisplay } from '../../constants/permissions';

function ManageUsers() {
  const { isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  const [isAdmin, setIsAdmin] = useState(false);
  const [loadingAdminStatus, setLoadingAdminStatus] = useState(true);

  const [availableUsers, setAvailableUsers] = useState([]);
  const [originalUserRoles, setOriginalUserRoles] = useState({});
  const [selectedRoles, setSelectedRoles] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [loadingUsers, setLoadingUsers] = useState(false);

  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const roles = ['Administrator', 'Project Manager', 'Estimator'];

  useEffect(() => {
    if (!isAuthenticated) navigate('/login');
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    const checkAdminAndFetchUsers = async () => {
      if (!isAuthenticated) return;
      setLoadingAdminStatus(true);
      try {
        const { is_admin } = await getIsAdmin();
        setIsAdmin(is_admin);

        if (is_admin) {
          await handleFetchUsers();
        }
      } catch (error) {
        setErrorMessage('Failed to determine admin status. Please try again later.');
      } finally {
        setLoadingAdminStatus(false);
      }
    };
    checkAdminAndFetchUsers();
  }, [isAuthenticated]);

  const handleFetchUsers = async () => {
    setLoadingUsers(true);
    setErrorMessage('');
    try {
      const response = await fetchUsers();
      setAvailableUsers(response.users);

      const rolesMap = {};
      const selectedRolesMap = {};
      response.users.forEach((userItem) => {
        const roleName = userItem.roles[0];
        rolesMap[userItem.email] = roleName;
        selectedRolesMap[userItem.email] = roleName;
      });
      setOriginalUserRoles(rolesMap);
      setSelectedRoles(selectedRolesMap);
    } catch (err) {
      setErrorMessage('Error fetching users. Please try again later.');
    } finally {
      setLoadingUsers(false);
    }
  };

  const handleRoleSelectChange = (email, role) => {
    setSelectedRoles((prev) => ({
      ...prev,
      [email]: role,
    }));
  };

  const handleUpdateUserRole = async (email) => {
    const newRole = selectedRoles[email];
    if (!newRole) {
      setSnackbar({ open: true, message: 'Please select a role.', severity: 'warning' });
      return;
    }

    // Optimistically update the UI
    setOriginalUserRoles((prev) => ({
      ...prev,
      [email]: newRole,
    }));

    try {
      await updateUserRole(email, newRole);
      setSnackbar({ open: true, message: `User role updated to ${newRole}.`, severity: 'success' });
    } catch (error) {
      setErrorMessage('Error updating user role. Please try again later.');
      // Revert the UI change on error
      setOriginalUserRoles((prev) => ({
        ...prev,
        [email]: prev[email],
      }));
      setSelectedRoles((prev) => ({
        ...prev,
        [email]: prev[email],
      }));
      setSnackbar({ open: true, message: 'Failed to update user role.', severity: 'error' });
    }
  };

  const getPermissionsForRole = (role) => {
    return rolePermissionsDisplay[role] || [];
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  if (!isAuthenticated) {
    return (
      <MainLayout>
        <Typography variant="h6" sx={{ mt: 10, textAlign: 'center' }}>
          You must be logged in to view this page.
        </Typography>
      </MainLayout>
    );
  }

  if (loadingAdminStatus) {
    return (
      <MainLayout>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
          <CircularProgress />
        </Box>
      </MainLayout>
    );
  }

  if (!isAdmin) {
    return (
      <MainLayout>
        <Box sx={{ textAlign: 'center', mt: 10 }}>
          <Typography variant="h6" color="error">
            You do not have permission to view this page.
          </Typography>
        </Box>
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <Box sx={{ maxWidth: '1200px', margin: 'auto', padding: 2, marginLeft: 35 }}>
        <Typography variant="h5" gutterBottom>
          Manage User Roles
        </Typography>

        {errorMessage && (
          <Paper sx={{ padding: 2, marginBottom: 2 }}>
            <Typography variant="body1" color="error">
              {errorMessage}
            </Typography>
          </Paper>
        )}

        {loadingUsers ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Card sx={{ p: 2 }}>
            <CardContent>
              {availableUsers.length === 0 ? (
                <Typography variant="body1">No users found in your company.</Typography>
              ) : (
                availableUsers.map((userItem) => {
                  const originalRole = originalUserRoles[userItem.email];
                  const selectedRole = selectedRoles[userItem.email];
                  const hasChanged = originalRole !== selectedRole;

                  return (
                    <Paper key={userItem.email} sx={{ padding: 2, marginBottom: 2 }}>
                      <Grid container spacing={2} alignItems="flex-start">
                        <Grid item xs={12} sm={4}>
                          <Typography variant="subtitle1">{userItem.email}</Typography>
                          <FormControl variant="outlined" fullWidth sx={{ mt: 1 }}>
                            <InputLabel>Role</InputLabel>
                            <Select
                              value={selectedRole || 'Estimator'}
                              onChange={(e) => handleRoleSelectChange(userItem.email, e.target.value)}
                              label="Role"
                            >
                              {roles.map((role) => (
                                <MenuItem key={role} value={role}>
                                  {role}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>

                        {/* Permissions Comparison */}
                        {hasChanged && (
                          <Grid item xs={12} sm={8}>
                            <Typography variant="subtitle2">Permissions Change:</Typography>
                            <Box sx={{ display: 'flex', gap: 4, mt: 1, flexWrap: 'wrap' }}>
                              <Box>
                                <Typography variant="body2" color="textSecondary">
                                  <strong>Current Permissions:</strong>
                                </Typography>
                                <List dense>
                                  {getPermissionsForRole(originalRole).length > 0 ? (
                                    getPermissionsForRole(originalRole).map((permName) => (
                                      <ListItem key={permName}>
                                        <ListItemIcon>
                                          <svg
                                            style={{ color: '#1976d2' }}
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                          >
                                            <path
                                              fill="currentColor"
                                              d="M9 16.17L4.83 12 3.41 13.41 9 19 21 7 19.59 5.58z"
                                            />
                                          </svg>
                                        </ListItemIcon>
                                        <ListItemText primary={permissionsDescriptions[permName]} />
                                      </ListItem>
                                    ))
                                  ) : (
                                    <Typography variant="body2">No permissions assigned.</Typography>
                                  )}
                                </List>
                              </Box>
                              <Box>
                                <Typography variant="body2" color="textSecondary">
                                  <strong>New Permissions:</strong>
                                </Typography>
                                <List dense>
                                  {getPermissionsForRole(selectedRole).length > 0 ? (
                                    getPermissionsForRole(selectedRole).map((permName) => (
                                      <ListItem key={permName}>
                                        <ListItemIcon>
                                          <svg
                                            style={{ color: '#4caf50' }}
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                          >
                                            <path
                                              fill="currentColor"
                                              d="M9 16.17L4.83 12 3.41 13.41 9 19 21 7 19.59 5.58z"
                                            />
                                          </svg>
                                        </ListItemIcon>
                                        <ListItemText primary={permissionsDescriptions[permName]} />
                                      </ListItem>
                                    ))
                                  ) : (
                                    <Typography variant="body2">No permissions assigned.</Typography>
                                  )}
                                </List>
                              </Box>
                            </Box>
                          </Grid>
                        )}

                        {/* Update Button */}
                        <Grid item xs={12} sm={2} sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleUpdateUserRole(userItem.email)}
                            disabled={!hasChanged}
                          >
                            Update
                          </Button>
                        </Grid>
                      </Grid>
                    </Paper>
                  );
                })
              )}
            </CardContent>
          </Card>
        )}

        {/* Snackbar for feedback */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </MainLayout>
  );
}

export default ManageUsers;
